var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { SplitButton } from "components";
import BusinessRow from "pages/Admin/ManageUser/GeneralTab/BusinessRow";
export default function GeneralTab(_a) {
    var _b, _c;
    var user = _a.user;
    var _d = useState(0), itemIndex = _d[0], setItemIndex = _d[1];
    var createItems = [
        {
            name: "Email All Invoices",
            icon: "plus",
            action: function () { },
        },
    ];
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: "Quick Actions" }), _jsxs("div", { className: "flex space-x-4", children: [_jsx(SplitButton, { items: createItems, listHeight: "424px", state: [itemIndex, setItemIndex] }), _jsx(SplitButton, { items: createItems, listHeight: "424px", color: "error", state: [itemIndex, setItemIndex], buttonPreName: "Danger Zone: " })] })] }), _jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: "Businesses" }), ((_b = user.managed_businesses) === null || _b === void 0 ? void 0 : _b.length) !== 0 && (_jsx(DataGrid, { sx: {
                            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "8px" },
                            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "15px" },
                            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "22px" },
                        }, slots: {
                            columnHeaders: function () { return null; },
                        }, rows: (_c = user.managed_businesses) === null || _c === void 0 ? void 0 : _c.map(function (b) { return (__assign(__assign({}, b), { id: b.public_id })); }), columns: [
                            {
                                flex: 1,
                                field: "",
                                renderCell: function (params) {
                                    return _jsx(BusinessRow, { business: params.row }, params.row.public_id);
                                },
                            },
                        ], getRowHeight: function () { return "auto"; }, hideFooter: true }))] })] }));
}
