import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControlLabel, IconButton, Switch } from "@mui/material";
import { T } from "@tolgee/react";
import { useState } from "react";
import { useParams } from "react-router";
import { BaseButton } from "components/Button";
import DataCategories from "components/DSRSettings/CCPA/DataCategories";
import { BaseModal } from "components/DataDisplay/Modal";
import SupportMessage from "components/Support/SupportMessage";
import { SupportMessageType } from "types/message";
import tolgee from "services/translation";
export default function DisclosingData(_a) {
    var isEnabled = _a.isEnabled, handleEnabled = _a.handleEnabled, discloseData = _a.discloseData, discloseDataOptions = _a.discloseDataOptions, handleCategory1Select = _a.handleCategory1Select, handleCategory2Select = _a.handleCategory2Select, handleDeletion = _a.handleDeletion, addEmptyCategory = _a.addEmptyCategory;
    var businessId = useParams().businessId;
    var _b = useState(false), openHelpModal = _b[0], setOpenHelpModal = _b[1];
    var getCategoryData = function (selectedItem) {
        var value = discloseDataOptions.filter(function (item) {
            return item.isAvailable &&
                (!item.selected || item.dataToSell.value === selectedItem.dataToSell.value);
        });
        return value.map(function (item) { return item.dataToSell.value; });
    };
    return (_jsxs("div", { className: "mt-4 mb-4 box-dotted", "data-testid": "disclosingData_component", children: [_jsxs("div", { className: "flex flex-row justify-between gap-8", children: [_jsxs("div", { children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.disclosing_title" }) }), _jsx("div", { children: _jsx(T, { keyName: "dsr.settings.ccpa.disclosing_description" }) })] }), _jsxs(Button, { variant: "text", onClick: function () { return setOpenHelpModal(true); }, children: [_jsx(T, { keyName: "generic.need_help" }), "?"] })] }), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(FormControlLabel, { control: _jsx(Switch, { checked: isEnabled, onChange: function (event) { return handleEnabled(event.target.checked); } }), label: isEnabled ? (_jsx(T, { keyName: "dsr.settings.ccpa.disclosing_switch_active" })) : (_jsx(T, { keyName: "dsr.settings.ccpa.sharing_switch_disabled" })) }) }), isEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: discloseData.map(function (item, index) { return (_jsxs("div", { className: "my-4 flex fle-row items-start justify-between gap-2", children: [_jsx("div", { className: "flex grow", children: _jsx(DataCategories, { categoryValue: item.dataToSell.value, categoryData: getCategoryData(item), categoryOptionsValue: item.sellTo.map(function (sellToItem) { return sellToItem.value; }), categoryOptionsData: item.sellOptions.map(function (sellOptionItem) { return sellOptionItem.value; }), handleCategorySelect: function (value) {
                                            return handleCategory1Select(item, index, value);
                                        }, handleOptionSelect: function (value) {
                                            return handleCategory2Select(item, index, value);
                                        } }) }), _jsx("div", { className: "flex py-1 border rounded-md hover:bg-slate-100 border-slate-300 bg-slate-50", children: _jsxs(IconButton, { size: "large", onClick: function () { return handleDeletion(item); }, children: [_jsx(FontAwesomeIcon, { icon: "trash", className: " text-brand-600", size: "xs" }), " "] }) })] }, item.dataToSell.value)); }) }), discloseDataOptions.filter(function (item) { return item.isAvailable; }).length > discloseData.length ? (_jsx("div", { className: "mb-4 w-full flex align-center justify-center", children: _jsx(BaseButton, { className: "w-auto", variant: "outlined", onClick: addEmptyCategory, children: _jsx(T, { keyName: "dsr.settings.ccpa.disclosing_add_category" }) }) })) : (""), _jsx("div", { className: "font-thin italic", children: _jsx(T, { keyName: "dsr.settings.ccpa.disclosing_note" }) })] })) : (""), _jsx(BaseModal, { modalState: [openHelpModal, setOpenHelpModal], children: _jsx(SupportMessage, { businessId: businessId, supportMessageType: SupportMessageType.CCPA_CONFIG_CATEGORY, modalTitle: tolgee.t("dsr.settings.ccpa.disclosing_title"), contentPlaceholder: tolgee.t("general.how_can_we_help"), openSupportModal: function (value) { return setOpenHelpModal(value); } }) })] }));
}
