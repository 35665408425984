var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { T } from "@tolgee/react";
import api from "services/api";
import { redirectToFlaskUrl } from "helpers";
import { ProgressBar, BaseButton } from "components";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import JurisdictionButton from "pages/Public/Dsr/Business/JurisdictionButton";
export default function DSRBusiness() {
    var publicId = useParams().publicId;
    var navigate = useNavigate();
    var _a = useState({}), businessState = _a[0], setBusinessState = _a[1];
    var _b = useState(false), showAll = _b[0], setShowAll = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var jurisdictions = useMemo(function () {
        if (businessState === null || businessState === void 0 ? void 0 : businessState.default_jurisdiction) {
            return [businessState === null || businessState === void 0 ? void 0 : businessState.default_jurisdiction].concat(businessState === null || businessState === void 0 ? void 0 : businessState.jurisdictions.filter(function (i) { return i !== (businessState === null || businessState === void 0 ? void 0 : businessState.default_jurisdiction); }));
        }
        return businessState === null || businessState === void 0 ? void 0 : businessState.jurisdictions;
    }, [businessState]);
    useEffect(function () {
        function getBusiness() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.dsr.getDsrBusiness(publicId)];
                        case 1:
                            res = _a.sent();
                            if (res) {
                                setBusinessState(res);
                                setShowAll(res.show_all);
                                document.title = "".concat(res.company_name, ": Select Jurisdiction");
                            }
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
        getBusiness();
    }, [publicId]);
    var handleClick = function (type) {
        if (type === "dsr_cal") {
            navigate("/dsr/".concat(publicId, "/ccpa"));
            return;
        }
        if (type === "dsr_fadp") {
            navigate("/dsr/".concat(publicId, "/fadp"));
            return;
        }
        redirectToFlaskUrl("/dsrtool/new/".concat(type, "/").concat(publicId));
    };
    return (_jsx(_Fragment, { children: businessState.public_id && !loading ? (_jsxs(RequestFormContainer, { headerState: businessState, children: [_jsx("h1", { className: "mt-10 text-4xl text-center text-brand-900", children: _jsx(T, { keyName: "generic.applicable_framework" }) }), _jsx("p", { className: "w-full max-w-lg mx-auto", children: businessState.explanatory_text }), _jsxs("div", { className: "grid w-full max-w-lg p-6 mx-auto overflow-hidden border rounded-lg", children: [_jsxs("h2", { className: "mt-0 text-lg", children: [_jsx(T, { keyName: "generic.i_want_to_make_a" }), "..."] }), _jsxs("div", { className: "grid gap-8", children: [!showAll && businessState.default_jurisdiction && (_jsxs(_Fragment, { children: [_jsx(JurisdictionButton, { handleClick: handleClick, jurisdictionKey: businessState.default_jurisdiction }), _jsx(BaseButton, { variant: "outlined", className: "self-center w-full", onClick: function () { return setShowAll(true); }, children: _jsx(T, { keyName: "generic.all_options" }) })] })), (showAll || !businessState.default_jurisdiction) &&
                                    (jurisdictions === null || jurisdictions === void 0 ? void 0 : jurisdictions.map(function (jurisdiction) { return (_jsx(JurisdictionButton, { handleClick: handleClick, jurisdictionKey: jurisdiction }, jurisdiction)); }))] })] })] })) : (_jsx(ProgressBar, {})) }));
}
