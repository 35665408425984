import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import clsx from "clsx";
import { LEGAL_INFO, PRIVACY_POLICY } from "const/env";
export default function EmptyLandingPage() {
    return (_jsx("div", { style: {
            backgroundSize: "cover",
            height: "100%",
        }, className: "relative flex flex-col items-center justify-center min-h-screen px-2 mx-auto sm:px-4", children: _jsxs("div", { className: clsx("flex flex-col w-full max-w-screen-lg p-6 m-4 shadow-2xl backdrop-blur-sm space-y-6 rounded-lg border pt-0"), children: [_jsx("div", { className: "flex mt-6 space-x-1 text-2xl font-bold md:text-4xl", children: _jsx("div", { children: _jsx(T, { keyName: "public_clp.compliance_center_no_business" }) }) }), _jsx("div", { children: _jsx(T, { keyName: "clp.client_with_no_reps.explanation" }) }), _jsx("div", { className: "flex justify-end", children: _jsxs("div", { className: "flex space-x-4 font-bold", children: [_jsx("a", { href: PRIVACY_POLICY, className: "text-black/80", children: _jsx(T, { keyName: "public_clp.privacy_policy" }) }), _jsx("a", { href: LEGAL_INFO, className: "text-black/80", children: _jsx(T, { keyName: "public_clp.legal" }) })] }) })] }) }));
}
