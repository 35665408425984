var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress, Tooltip, styled, tooltipClasses } from "@mui/material";
import { useMemo } from "react";
import clsx from "clsx";
import DSRMenu from "pages/Client/Case/DSR/ClientView/Components/DSRMenu";
import DeadlineMenu from "pages/Client/Case/DSR/ClientView/Components/DSRHeader/DeadlineMenu";
import DeclineRequestButton from "pages/Client/Case/DSR/ClientView/Components/DeclineRequestButton";
import { generateI18nKeyByCaseType, getCurrentCaseStage } from "helpers/case";
import { stageNames } from "const/dsr";
import { BaseButton } from "components";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
var stageIcons = ["clipboard", "clipboard-check", "edit", "paper-plane"];
export default function DSRHeader() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var currentStage = useMemo(function () { return getCurrentCaseStage(caseData); }, [caseData]);
    var requestClosed = caseData.state === "CLOSED";
    var LightTooltip = styled(function (_a) {
        var className = _a.className, props = __rest(_a, ["className"]);
        return (_jsx(Tooltip, __assign({}, props, { classes: { popper: className } })));
    })(function (_a) {
        var _b;
        var theme = _a.theme;
        return (_b = {},
            _b["& .".concat(tooltipClasses.tooltip)] = {
                backgroundColor: theme.palette.common.white,
                color: "rgba(0, 0, 0, 0.87)",
                boxShadow: theme.shadows[1],
                fontSize: 11,
            },
            _b);
    });
    var getStageColor = function (stage) {
        if (requestClosed &&
            currentStage !== "DONE" &&
            stageNames.indexOf(stage) >= stageNames.indexOf(currentStage)) {
            return "bg-white text-gray-500";
        }
        if (currentStage === "DONE" || stageNames.indexOf(stage) < stageNames.indexOf(currentStage)) {
            return "bg-success text-white";
        }
        if (stage === currentStage) {
            return "bg-warning text-white";
        }
        return "bg-white text-gray-500";
    };
    var changeUIStage = function (newStage) {
        if ((requestClosed && newStage === "HANDLING_REVIEW") ||
            currentStage === "DONE" ||
            stageNames.indexOf(newStage) <= stageNames.indexOf(currentStage)) {
            dispatch({ type: "UPDATE_UI_STAGE", payload: newStage });
        }
    };
    return (_jsxs("div", { className: "flex flex-row items-center justify-between w-full pb-4", "data-testid": "dsr-header", children: [_jsx("div", { className: "border rounded-md h-[38px] w-fit flex cursor-default font-bold", children: stageNames.map(function (stage, index) { return (_jsx(LightTooltip, { title: tolgee.t({
                        key: generateI18nKeyByCaseType(caseData.type, "".concat(stage, ".tooltip")),
                    }), onClick: function () { return changeUIStage(stage); }, children: _jsxs("div", { className: clsx("border-r flex items-center px-3 space-x-2", getStageColor(stage), {
                            "!bg-danger text-white": requestClosed,
                            "rounded-s-md": index === 0,
                        }), children: [_jsx(FontAwesomeIcon, { icon: stageIcons[index], size: "1x" }), stage === state.ui.stage && (_jsx("div", { className: "hidden @xl:block whitespace-nowrap", children: tolgee.t({
                                    key: generateI18nKeyByCaseType(caseData.type, stage),
                                }) }))] }) }, stage)); }) }), _jsxs("div", { className: "flex @xl:w-none w-fit @xl:justify-normal space-x-2 items-center", children: [state.loading && (_jsx("div", { className: "mr-2 text-gray-400 text-center flex justify-center", children: _jsx(CircularProgress, { color: "inherit", size: 32 }) })), caseData.state !== "CLOSED" && _jsx(DeclineRequestButton, {}), !requestClosed && _jsx(DeadlineMenu, {}), _jsx(BaseButton, { onClick: function () { return dispatch({ type: "SEND_INQUIRY" }); }, disabled: requestClosed, children: tolgee.t({
                            key: generateI18nKeyByCaseType(caseData.type, "send_generic_inquiry"),
                        }) }), _jsx(DSRMenu, { isRequestClosed: requestClosed })] })] }));
}
