var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { T } from "@tolgee/react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { api } from "services";
import tolgee from "services/translation";
import { dataGridConfig } from "helpers/dataGridConfig";
import { BaseIconButton, LinkButton, SearchField } from "components";
import { useDebounce } from "helpers/hooks";
import { getBeautyDate, getUrl } from "helpers";
import { DANGER, PRIGHTER_BLUE, SUCCESS } from "const/color";
import { useAppSelector } from "store";
import { getTimeDifferenceString } from "helpers/date";
import { notify } from "store/app";
import { DEV_ENV } from "const/env";
var allowedRoles = ["ADMIN", "MANAGER-GHOSTLOGIN"];
export default function ManageUsers() {
    var _this = this;
    var _a = useState(), editableNote = _a[0], setEditableNote = _a[1];
    var _b = useForm(), register = _b.register, getValues = _b.getValues;
    var dispatch = useDispatch();
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    var roles = useAppSelector(function (state) { return state.user; }).roles;
    var _c = useState(""), search = _c[0], setSearch = _c[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(), data = _e[0], setData = _e[1];
    var _f = useState(0), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = useState("created_at"), orderedBy = _g[0], setOrderedBy = _g[1];
    var _h = useState("desc"), newOrder = _h[0], setNewOrder = _h[1];
    var _j = useState(25), rowsPerPage = _j[0], setRowsPerPage = _j[1];
    var _k = useState(DEV_ENV ? "demo" : "active"), filter = _k[0], setFilter = _k[1];
    var handlefFilter = function (event, newFilter) {
        if (newFilter !== null) {
            setFilter(newFilter);
        }
    };
    useEffect(function () {
        document.title = tolgee.t({
            key: "users.manage_users",
        });
    }, []);
    var addNote = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        var note, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    note = { note: getValues("answers.".concat(uuid, ".answer")) };
                    return [4 /*yield*/, api.user.setNote(uuid, note)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "users.note_added",
                            }),
                            type: "SUCCESS",
                        }));
                        setEditableNote("");
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSendOpenInvoices = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.invoice.getSendOpenInvoices(uuid)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.open_invoices_sent",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchData = function (props) { return __awaiter(_this, void 0, void 0, function () {
        var order_by, page, per_page, order, search_term, demo, deleted, desc, res, tableData;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoading(true);
                    order_by = props.order_by, page = props.page, per_page = props.per_page, order = props.order, search_term = props.search_term, demo = props.demo, deleted = props.deleted;
                    desc = order === "desc";
                    return [4 /*yield*/, api.user.list({
                            order_by: order_by,
                            page: page + 1,
                            per_page: per_page,
                            desc: desc,
                            search_term: search_term,
                            demo: demo,
                            deleted: deleted,
                            customer: true,
                            partner: false,
                        })];
                case 1:
                    res = _b.sent();
                    if (!res) {
                        setLoading(false);
                        return [2 /*return*/];
                    }
                    tableData = __assign(__assign({}, dataGridConfig({ currentPage: currentPage, rowsPerPage: rowsPerPage, count: res.count })), { onSortModelChange: function (sortVal) {
                            if ((sortVal === null || sortVal === void 0 ? void 0 : sortVal.length) === 0) {
                                return;
                            }
                            setOrderedBy(sortVal[0].field);
                            setNewOrder(sortVal[0].sort);
                        }, onPaginationModelChange: function (val) {
                            setCurrentPage(val.page);
                            setRowsPerPage(val.pageSize);
                        }, columns: [
                            {
                                flex: 0.75,
                                field: "created_at",
                                headerName: tolgee.t({
                                    key: "generic.created_at",
                                }),
                                renderCell: function (params) {
                                    var _a, _b;
                                    return (_jsx(Tooltip, { title: _jsxs("div", { children: [getTimeDifferenceString((_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.created_at, true), " ago"] }), placement: "top", children: _jsx("div", { children: getBeautyDate((_b = params === null || params === void 0 ? void 0 : params.row) === null || _b === void 0 ? void 0 : _b.created_at) }) }));
                                },
                            },
                            {
                                flex: 1.5,
                                field: "company_name",
                                headerName: tolgee.t({
                                    key: "users.company_name",
                                }),
                                renderCell: function (params) {
                                    var _a, _b, _c, _d, _e, _f;
                                    return (_jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx("div", { children: (_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.company_name }), _jsx(IconButton, { "data-testid": "add-address-button", onClick: function () { var _a; return setEditableNote((_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.uuid); }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "edit", color: PRIGHTER_BLUE }) })] }), ((_b = params === null || params === void 0 ? void 0 : params.row) === null || _b === void 0 ? void 0 : _b.note) && editableNote !== ((_c = params === null || params === void 0 ? void 0 : params.row) === null || _c === void 0 ? void 0 : _c.uuid) && (_jsx("div", { className: "italic", children: (_d = params === null || params === void 0 ? void 0 : params.row) === null || _d === void 0 ? void 0 : _d.note })), editableNote === ((_e = params === null || params === void 0 ? void 0 : params.row) === null || _e === void 0 ? void 0 : _e.uuid) && (_jsxs("div", { className: "pb-4 flex space-x-2", children: [_jsx(TextField, __assign({ className: "w-full", label: tolgee.t({
                                                            key: "users.note",
                                                        }), onKeyDown: function (event) { return event.stopPropagation(); } }, register("answers.".concat((_f = params === null || params === void 0 ? void 0 : params.row) === null || _f === void 0 ? void 0 : _f.id, ".answer")))), _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsx(IconButton, { "data-testid": "check", onClick: function () { var _a; return addNote((_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.id); }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "check", color: SUCCESS.DEFAULT }) }), _jsx(IconButton, { "data-testid": "xmark", onClick: function () { return setEditableNote(""); }, size: "small", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "xmark", color: DANGER.DEFAULT }) })] })] }))] }));
                                },
                            },
                            {
                                flex: 1.75,
                                field: "email",
                                headerName: tolgee.t({
                                    key: "users.email",
                                }),
                            },
                            {
                                flex: 1,
                                field: "public_ids",
                                headerName: tolgee.t({
                                    key: "users.businesses",
                                }),
                                renderCell: function (params) {
                                    var _a, _b;
                                    return (_jsx("div", { className: "flex flex-col -ml-2 p-2", children: (_b = (_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.public_ids) === null || _b === void 0 ? void 0 : _b.map(function (i) { return (_jsx(LinkButton, { className: "!p-0", color: "NONE", href: getUrl("FLASK", "/administration/business/".concat(i)), children: _jsx("div", { children: i }) }, i)); }) }));
                                },
                            },
                            {
                                flex: 1,
                                field: "products",
                                headerName: tolgee.t({
                                    key: "users.products",
                                }),
                                sortable: false,
                                renderCell: function (params) {
                                    var _a;
                                    return (_jsx("div", { className: "flex  space-x-2", children: (_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.product_icons.map(function (i) { return _jsx("div", { children: i }, i); }) }));
                                },
                            },
                            {
                                flex: 0.5,
                                field: "iso_3166_1_alpha_2",
                                headerName: tolgee.t({
                                    key: "users.country",
                                }),
                                renderCell: function (params) {
                                    var _a;
                                    var country = countries === null || countries === void 0 ? void 0 : countries.find(function (i) { var _a; return i.iso_3166_1_alpha_2 === ((_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.iso_3166_1_alpha_2); });
                                    return (_jsxs("div", { className: "flex space-x-2 items-center", children: [_jsx("div", { children: (_a = params === null || params === void 0 ? void 0 : params.row) === null || _a === void 0 ? void 0 : _a.iso_3166_1_alpha_2 }), _jsx("div", { children: country === null || country === void 0 ? void 0 : country.flag })] }));
                                },
                            },
                            {
                                flex: 0.5,
                                field: "role_names",
                                headerName: tolgee.t({
                                    key: "users.type",
                                }),
                                renderCell: function (params) {
                                    var _a = params.row, isDemo = _a.demo, light = _a.light, role_names = _a.role_names;
                                    var type;
                                    if (isDemo) {
                                        type = "D";
                                    }
                                    else if (light) {
                                        type = "L";
                                    }
                                    else if (role_names.includes("partner")) {
                                        type = "P";
                                    }
                                    else {
                                        type = "C";
                                    }
                                    return _jsx("div", { className: "ml-2", children: type });
                                },
                            },
                            {
                                flex: 1,
                                field: "buttons",
                                headerName: "",
                                sortable: false,
                                renderCell: function (params) {
                                    return (_jsxs("div", { className: "space-x-2 flex", children: [_jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/customer/".concat(params.id, "/invoices")), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "file-invoice-dollar", color: PRIGHTER_BLUE }) }) }), _jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/manage_customer/".concat(params.id)), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "user", color: PRIGHTER_BLUE }) }) }), roles.some(function (role) { return allowedRoles.includes(role.name); }) && (_jsx(LinkButton, { color: "NONE", href: getUrl("FLASK", "/administration/customer/".concat(params.id, "/ghostlogin")), children: _jsx("div", { className: "w-3 h-3 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { size: "lg", icon: "ghost", color: PRIGHTER_BLUE }) }) })), roles.some(function (role) {
                                                return ["ADMIN", "DEV", "MANAGER_FINANCIALREPORTING"].includes(role.name);
                                            }) && (_jsx(IconButton, { onClick: function () { return handleSendOpenInvoices(params.id); }, size: "small", children: _jsx(FontAwesomeIcon, { icon: "envelopes-bulk", color: PRIGHTER_BLUE }) }))] }));
                                },
                            },
                        ], rows: (_a = res === null || res === void 0 ? void 0 : res.result) === null || _a === void 0 ? void 0 : _a.map(function (i) {
                            return __assign(__assign({}, i), { id: i === null || i === void 0 ? void 0 : i.uuid });
                        }) });
                    setData(tableData);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchData({
            order_by: orderedBy,
            page: currentPage,
            per_page: rowsPerPage,
            order: newOrder,
            search_term: debouncedSearchValue,
            demo: filter === "active" ? false : filter === "demo",
            deleted: filter === "active" ? false : filter === "deleted",
            customer: true,
            partner: false,
        });
    }, [currentPage, rowsPerPage, orderedBy, newOrder, debouncedSearchValue, filter, editableNote]);
    return (_jsx("div", { className: "flex flex-col mx-auto max-w-7xl", "data-testid": "partners", children: data ? (_jsxs("div", { className: "box-outerlayout", children: [_jsxs("div", { className: "flex space-x-4 items-center", children: [_jsx(BaseIconButton, { className: "border-full", onClick: function () {
                                return fetchData({
                                    order_by: orderedBy,
                                    page: currentPage,
                                    per_page: rowsPerPage,
                                    order: newOrder,
                                    search_term: debouncedSearchValue,
                                    demo: filter === "demo",
                                    deleted: filter === "deleted",
                                    customer: true,
                                    partner: false,
                                });
                            }, children: _jsx("div", { className: "w-4 h-4 flex items-center justify-center", children: _jsx(FontAwesomeIcon, { icon: "refresh", color: "gray" }) }) }), _jsx(SearchField, { className: "w-full", searchState: [search, setSearch] }), _jsx("div", { className: "space-x-2 flex items-center", children: _jsxs(ToggleButtonGroup, { exclusive: true, className: "h-14", value: filter, onChange: handlefFilter, children: [_jsx(ToggleButton, { value: "active", children: _jsx(T, { keyName: "users.active" }) }), _jsx(ToggleButton, { value: "deleted", children: _jsx(T, { keyName: "users.deleted" }) }), _jsx(ToggleButton, { value: "demo", children: _jsx(T, { keyName: "users.demo" }) })] }) })] }), _jsx(DataGrid, __assign({}, data, { loading: loading, getRowClassName: function (params) { return "".concat(params.row.is_main_client && "font-bold"); } }))] })) : (!loading && (_jsx("div", { children: tolgee.t({
                key: "billing.no_data",
            }) }))) }));
}
