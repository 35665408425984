import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, List, ListItemButton, ListItemText, Popover, Skeleton } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router";

import { PRIGHTER_BLUE } from "const/color";
import { getUrl } from "helpers";
import { useAppDispatch, useAppSelector } from "store";
import { changeGuide, toggleNavbar } from "store/app";
import { getGuideTypeLabel } from "const";
import { DEV_ENV, IS_PROD_DEV } from "const/env";
import PrighterLogo from "components/Prighter/PrighterLogo";
import { LinkButton } from "components/Button";
import { isManager } from "helpers/general";

function Header() {
  const {
    email,
    name,
    demo,
    role,
    roles,
    userLoaded = false,
  } = useAppSelector((state) => state.user);
  const { guideList } = useAppSelector((state) => state.app.guide);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleRunGuide = (guideType) => {
    setAnchorEl(null);
    dispatch(changeGuide({ running: true, activeGuide: guideType }));
  };

  const handleClick = (event) => {
    if (guideList.length === 1) {
      handleRunGuide(guideList[0]);
    }
    setAnchorEl(event.currentTarget);
  };

  return (
    <header className="fixed z-40 w-screen px-4 border-b border-gray-300 bg-gray-50">
      <div className="box-border grid items-center grid-cols-2 py-3 mx-auto">
        <div className="flex space-x-4">
          <IconButton
            size="medium"
            onClick={() => {
              dispatch(toggleNavbar());
            }}>
            <div className="flex items-center justify-center w-3 h-3">
              <FontAwesomeIcon size="lg" icon="bars" color={PRIGHTER_BLUE} />
            </div>
          </IconButton>
          <Link
            className="flex items-baseline space-x-4"
            to={isManager(roles) ? "/manage-users" : ""}>
            <PrighterLogo className="h-8" width={24.4} />
            <span className="hidden text-4xl font-bold text-prighterblue md:flex">Prighter</span>
          </Link>
        </div>
        <nav className="items-center justify-end text-base text-right ">
          <div className="flex flex-row items-center justify-end space-x-2">
            {(demo || ["DEV", "ADMIN"].includes(role) || DEV_ENV || IS_PROD_DEV) &&
              guideList.length > 0 && (
                <>
                  <IconButton size="medium" onClick={handleClick}>
                    <div className="flex items-center justify-center w-3 h-3">
                      <FontAwesomeIcon size="xs" icon="info" color={PRIGHTER_BLUE} />
                    </div>
                  </IconButton>
                  {guideList.length > 1 && (
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}>
                      <List>
                        {guideList.map((option) => (
                          <ListItemButton key={option} onClick={() => handleRunGuide(option)}>
                            <ListItemText primary={getGuideTypeLabel(option)} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Popover>
                  )}
                </>
              )}

            {userLoaded ? (
              <div className="mr-2 md:mr-0 ">
                <div className="text-gray-800">{name || "None"}</div>
                <div className="w-48 overflow-hidden font-bold truncate text-prighterblue md:w-auto">
                  {email}
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-end space-y-2">
                <Skeleton variant="rectangular" width={120} height={20} />
                <Skeleton variant="rectangular" width={160} height={20} />
              </div>
            )}
            <LinkButton href={getUrl("FLASK", "/logout")} className="px-4">
              Sign out
            </LinkButton>

            <a href={getUrl("FLASK", "/logout")} size="medium" className="md:hidden">
              <FontAwesomeIcon icon="arrow-right-from-bracket" size="xl" color={PRIGHTER_BLUE} />
            </a>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
