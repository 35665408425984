import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, FormControlLabel, Switch } from "@mui/material";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppSelector } from "store";
export default function PrivacyPolicyDsr(_a) {
    var isEnabled = _a.isEnabled, handleEnabled = _a.handleEnabled;
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var businessId = useParams().businessId;
    var _b = useState(), isPrivacyPolicyExists = _b[0], setIsPrivacyPolicyExists = _b[1];
    useEffect(function () {
        var _a, _b;
        var value = !!((_b = (_a = managed_businesses === null || managed_businesses === void 0 ? void 0 : managed_businesses.find(function (item) { return item.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.config) === null || _b === void 0 ? void 0 : _b.privacy_policy_ext_url);
        setIsPrivacyPolicyExists(value);
    }, [managed_businesses]);
    return (_jsxs("div", { className: "box-dotted", children: [_jsx("h3", { className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.privacy_policy.title" }) }), isPrivacyPolicyExists ? ("") : (_jsx(Alert, { severity: "warning", className: "mb-2 mt-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.privacy_policy.warningLabel" }) })), _jsx("hr", { className: "h-px my-4 bg-gray-200 border-0" }), _jsx("div", { className: "mb-4", children: _jsx(FormControlLabel, { control: _jsx(Switch, { "data-testid": "privacyPolicy_switch", checked: isEnabled, onChange: function (event) { return handleEnabled(event.target.checked); } }), label: isEnabled ? (_jsx(T, { keyName: "dsr.settings.ccpa.privacy_policy_switch_active" })) : (_jsx(T, { keyName: "dsr.settings.ccpa.privacy_policy_switch_disabled" })) }) })] }));
}
