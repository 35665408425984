var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { T } from "@tolgee/react";
import { BaseTextField } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
var products = ["art27", "ukrep", "swissrep", "turkeyrep"];
export default function Certificate(_a) {
    var _this = this;
    var type = _a.type;
    var dispatch = useDispatch();
    var businessId = useParams().businessId;
    var _b = useState(), data = _b[0], setData = _b[1];
    var fetchData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.business.getCertificates(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    setData(res === null || res === void 0 ? void 0 : res.filter(function (i) { return i.product === type; })[0]);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId, dispatch, type]);
    useEffect(function () {
        fetchData();
    }, [businessId, fetchData]);
    var getHTML = function () {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(data === null || data === void 0 ? void 0 : data.html);
    };
    return (_jsx("div", { className: "".concat(products.includes(type) ? "box-outerlayout" : "", " pb-6 pt-10 mt-6"), "data-testid": "".concat(type, "-certificate"), children: _jsxs("div", { className: "md:p-6 w-full", children: [_jsx("h2", { children: _jsx(T, { keyName: "rep.".concat(type, "_certificate") }) }), _jsx("div", { className: "flex justify-center mb-12", children: _jsx("img", { src: data === null || data === void 0 ? void 0 : data.image_url, className: "mt-2 w-48", alt: data === null || data === void 0 ? void 0 : data.image_alt }) }), _jsx("div", { children: _jsx(T, { keyName: "rep.".concat(type, "_you_may_use") }) }), _jsx("div", { className: "font-bold text-lg mt-8", children: _jsx(T, { keyName: "rep.embed_html" }) }), _jsxs("div", { className: "mt-4", children: [_jsx(T, { keyName: "rep.you_may_include" }), ":"] }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4", children: [_jsx("div", { className: "mb-6", children: _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, disabled: true, value: data === null || data === void 0 ? void 0 : data.html }) }), _jsx("div", { className: "flex justify-end mt-4", children: _jsxs(Button, { onClick: function () { return getHTML(); }, "data-testid": "copy-btn", children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: "copy", color: "gray", size: "lg" }), _jsx(T, { keyName: "generic.copy" })] }) })] }), _jsx("div", { className: "italic", children: _jsx(T, { keyName: "rep.feel_free" }) })] }) }));
}
