import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function EmailCasesListItem(props) {
    var email = props.email, fetchEmailById = props.fetchEmailById, workflow = props.workflow;
    var navigate = useNavigate();
    var handleClick = function (id) { return function (e) {
        switch (e.detail) {
            case 1:
                fetchEmailById(id);
                break;
            case 2:
                navigate("/email/case/".concat(id), { state: workflow });
                break;
            default:
                (function (count) {
                    throw new Error("Cannot determine click count:  ".concat(count));
                })(e.detail);
        }
    }; };
    var handleEditClick = function (id) { return function () {
        navigate("/email/case/".concat(id), { state: workflow });
    }; };
    return (_jsxs("div", { className: "flex group min-h-16 items-start transition-all justify-between p-2 border border-[#E3E3E3] w-full rounded-md hover:bg-slate-100 focus:outline-none focus:ring focus:ring-prighterblue", children: [_jsxs("button", { type: "button", onClick: handleClick(email.uuid), className: "w-full", children: [_jsxs("div", { className: "flex items-center gap-4 mb-1", children: [_jsxs("h5", { className: "", children: [email.subject, " ", _jsx("span", { className: "font-bold", children: email.from_name })] }), _jsx("span", { className: "text-xs capitalize", children: email.state.toLocaleLowerCase() }), _jsxs("span", { className: "text-xs font-bold", children: ["(", email.tags.map(function (tag) { return "emailCase.tags.".concat(tag); }).join(", "), ")"] })] }), _jsxs("div", { className: "flex items-center gap-4 mt-2", children: [_jsx("h5", { className: "text-xs", children: new Date(email.received_at).toLocaleString() }), _jsx("h5", { className: "text-xs font-bold bg-[#E3E7EF] px-2 py-1 rounded-3xl", children: email.from_address })] })] }, email.uuid), _jsx("button", { type: "button", className: "w-12 h-12 rounded-lg group-hover:bg-slate-200 bg-brand-100", onClick: handleEditClick(email.uuid), children: _jsx(FontAwesomeIcon, { icon: "arrow-right" }) })] }));
}
