import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import GeoLocation from "pages/Client/Case/DSR/ClientView/Components/GeoLocation";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { generateI18nKey } from "helpers/case";
export default function DSLocation() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var checkStep = state.ui.check;
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "flex flex-col space-y-4", children: [_jsx("div", { children: _jsx(ConfirmInquiry, { handleSendInquiry: function () {
                            return dispatch({
                                type: "SEND_INQUIRY",
                                payload: ["location_1"],
                            });
                        }, declinePreview: "RESIDENCY" }) }), _jsx("div", { children: tolgee.t({
                        key: generateI18nKey(caseData.type, checkStep, "description"),
                    }) }), _jsx(GeoLocation, { geoLocation: state.case.extra.ip_geo_location, ipAddress: state.case.extra.ip_address, physicalAddress: state.case.extra.address })] }) }));
}
