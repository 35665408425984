import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, VerticalDataValue } from "components";
import { stringifyAddress } from "helpers/general";
export default function Header(_a) {
    var user = _a.user;
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex space-x-8", children: [_jsx("h1", { children: user.email }), _jsxs("div", { className: "flex space-x-2", children: [_jsx(VerticalDataValue, { title: "Standing", value: _jsx(Chip, { title: "Good" }) }), _jsx(VerticalDataValue, { title: "Status", value: _jsx(Chip, { title: user.active ? "Active" : "Inactive", backgroundColor: user.active ? "bg-success" : "bg-warning" }) }), _jsx(VerticalDataValue, { title: "Marketing Consent", value: _jsx(Chip, { title: user.marketing_opt_in ? "Given" : "Not Given", backgroundColor: user.marketing_opt_in ? "bg-success" : "bg-warning" }) })] })] }), _jsxs("div", { className: "grid grid-cols-[auto_auto_auto] grid-rows-[auto_auto] gap-x-4 gap-y-1 max-w-[45%] h-min", children: [_jsx(VerticalDataValue, { title: "Name", value: user.name }), _jsxs("div", { className: "flex space-x-2", children: [_jsx(VerticalDataValue, { title: "Paid", value: 2 }), _jsx(VerticalDataValue, { title: "Partially Paid", value: 1 }), _jsx(VerticalDataValue, { title: "Open", value: 3 }), _jsx(VerticalDataValue, { title: "Void", value: 4 }), _jsx(VerticalDataValue, { title: "Refunded", value: 2 })] }), _jsx(VerticalDataValue, { title: "Postal Address", value: stringifyAddress({
                            street: user.street,
                            postal: user.postal,
                            city: user.city,
                        }) }), _jsx(VerticalDataValue, { title: "Role", value: user.company_representative_role }), _jsx(VerticalDataValue, { title: "ID", value: user.uuid }), _jsx(VerticalDataValue, { title: "Payment Method", value: "Stripe" })] })] }));
}
