var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Checkbox, TextField } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { captureException } from "@sentry/react";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { BaseSelectField, FormFileField } from "components";
import { validateEmail } from "helpers/validate";
import tolgee from "services/translation";
import AddressForm from "components/complex/AddressForm";
export default function IdentificationForm(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var checkboxState = _a.checkboxState, identificationData = _a.identificationData;
    var _p = useState(false), sameEmail = _p[0], setSameEmail = _p[1];
    var _q = useState("signedPermission"), legalRepresentativeFile = _q[0], setLegalRepresentativeFile = _q[1];
    var residentOfCalifornia = checkboxState[0], setResidentOfCalifornia = checkboxState[1];
    var _r = useFormContext(), register = _r.register, watch = _r.watch, getValues = _r.getValues, setValue = _r.setValue, errors = _r.formState.errors;
    var acting_for = watch("actors.1.acting_for");
    var files = watch("files");
    var senderEmail = watch("actors.1.email");
    var identData = {
        name: watch("identifiable_info.name"),
        email: watch("identifiable_info.email"),
        address: watch("identifiable_info.address"),
        phone: watch("identifiable_info.phone"),
        customer_number: watch("identifiable_info.customer_number"),
        purchase_number: watch("identifiable_info.purchase_number"),
        pseudonym: watch("identifiable_info.pseudonym"),
        other: watch("identifiable_info.other"),
    };
    useEffect(function () {
        if (sameEmail) {
            setValue("identifiable_info.email", senderEmail);
        }
    }, [senderEmail, sameEmail, setValue]);
    register("actors.1.acting_for", {
        validate: {
            minLength: function (e) { return "".concat(e).trim().length > 0 || "Please choose something."; },
        },
    });
    register("files", {
        validate: {
            required: function (e) { return e.length > 0 || "Please add at least 1 file."; },
        },
    });
    var handleUploadFiles = function (fileList) {
        var fileArray = Array.from(fileList);
        var ccpaFileArray = fileArray.map(function (file) {
            return new Promise(function (resolve, reject) {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var base64String = reader.result;
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        });
        Promise.all(ccpaFileArray)
            .then(function (resolvedFileArray) {
            setValue("files", resolvedFileArray.map(function (f, ind) { return ({ name: fileArray[ind].name, content: f }); }));
        })
            .catch(function (error) {
            captureException(error);
        });
        return true;
    };
    var handleCheckboxChange = function (event) {
        setSameEmail(event.target.checked);
        if (event.target.checked) {
            setValue("identifiable_info.email", getValues("actors.1.email"));
        }
    };
    return (_jsxs("div", { children: [_jsxs("div", { className: "flex space-x-2 mt-6", children: [_jsx(Checkbox, { checked: residentOfCalifornia, style: { padding: 0, margin: 0, marginRight: 2 }, onChange: function (event) { return setResidentOfCalifornia(event.target.checked); } }), _jsxs("h2", { children: [_jsx(T, { keyName: "ccpa.i_am_resident" }), "*"] })] }), _jsxs("h2", { children: [_jsx(T, { keyName: "dsr.requester" }), ":"] }), _jsxs("div", { className: "flex flex-col space-y-4 mb-8", children: [_jsx(BaseSelectField, { error: !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_b = errors === null || errors === void 0 ? void 0 : errors.actors[1]) === null || _b === void 0 ? void 0 : _b.acting_for), required: true, state: [acting_for, function (val) { return setValue("actors.1.acting_for", val); }], label: tolgee.t({
                            key: "ccpa.ds_info.acting_for",
                        }), options: {
                            MYSELF: tolgee.t({
                                key: "ccpa.ds_info.acting_for.myself",
                            }),
                            AS_PARENT: tolgee.t({
                                key: "ccpa.ds_info.acting_for.as_parent",
                            }),
                            AS_LEGAL_REPRESENTATIVE: tolgee.t({
                                key: "ccpa.ds_info.acting_for.as_legal_representative",
                            }),
                        } }), !!(errors === null || errors === void 0 ? void 0 : errors.actors) && !!((_c = errors === null || errors === void 0 ? void 0 : errors.actors[1]) === null || _c === void 0 ? void 0 : _c.acting_for) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "ccpa.error_choose_acting_for" }) })), _jsx(TextField, __assign({ label: tolgee.t({
                            key: "dsr.email.for_contact",
                        }) }, register("actors.1.email", {
                        validate: {
                            minLength: function (e) { return "".concat(e).length >= 4 || "Minimum length of your email should be 4."; },
                            validEmail: function (e) { return validateEmail(e) || "You need to add a valid email."; },
                        },
                    }), { error: !!((_e = (_d = errors.actors) === null || _d === void 0 ? void 0 : _d[1]) === null || _e === void 0 ? void 0 : _e.email), helperText: (_h = (_g = (_f = errors.actors) === null || _f === void 0 ? void 0 : _f[1]) === null || _g === void 0 ? void 0 : _g.email) === null || _h === void 0 ? void 0 : _h.message, required: true })), acting_for && acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx(TextField, __assign({ label: tolgee.t({
                                    key: "generic.name",
                                }) }, register("actors.1.name", {
                                validate: {
                                    minLength: function (e) {
                                        return "".concat(e).length >= 4 || "Minimum length of your name should be 4.";
                                    },
                                },
                            }), { error: !!((_k = (_j = errors.actors) === null || _j === void 0 ? void 0 : _j[1]) === null || _k === void 0 ? void 0 : _k.name), helperText: (_o = (_m = (_l = errors.actors) === null || _l === void 0 ? void 0 : _l[1]) === null || _m === void 0 ? void 0 : _m.name) === null || _o === void 0 ? void 0 : _o.message, required: true })), acting_for === "AS_LEGAL_REPRESENTATIVE" && (_jsx(BaseSelectField, { label: tolgee.t({
                                    key: "ccpa.ds_info.as_legal_representative.file_type",
                                }), options: { signedPermission: "Signed Permission", poa: "Power of Attorney" }, state: [legalRepresentativeFile, setLegalRepresentativeFile] }))] })), identificationData.document_upload || (acting_for && acting_for !== "MYSELF") ? (_jsxs("div", { children: [_jsxs("span", { className: "font-bold", children: [acting_for === "MYSELF" && (_jsx("div", { children: _jsx(T, { keyName: "ccpa.ds_info.file_upload.myself" }) })), acting_for === "AS_PARENT" && _jsx(T, { keyName: "ccpa.ds_info.file_upload.as_parent" }), acting_for === "AS_LEGAL_REPRESENTATIVE" &&
                                        legalRepresentativeFile === "signedPermission" && (_jsx(T, { keyName: "ccpa.ds_info.file_upload.as_legal_representative.signed_permission" })), acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (_jsx(T, { keyName: "ccpa.ds_info.file_upload.as_legal_representative.poa" }))] }), _jsx(FormFileField, { label: "Upload a file", className: "mt-2", value: files, onChange: handleUploadFiles, multiple: true, fullWidth: true, required: true }), acting_for === "AS_LEGAL_REPRESENTATIVE" && legalRepresentativeFile === "poa" && (_jsx("span", { className: "text-gray-500", children: _jsx(T, { keyName: "ccpa.ds_info.file_upload.as_legal_representative.poa.text" }) })), !!(errors === null || errors === void 0 ? void 0 : errors.files) && (_jsx("span", { className: "text-danger pl-4 text-sm", children: _jsx(T, { keyName: "ccpa.error.sender_upload_at_least_one_file" }) }))] })) : ("")] }), _jsxs("h2", { className: "mt-8", children: [_jsx(T, { keyName: "dsr.data_subject" }), ":"] }), identificationData.required_inputs.map(function (item) {
                var _a, _b, _c, _d, _e, _f, _g;
                return (_jsxs("div", { children: [item === "email" && (_jsxs("div", { className: "flex flex-col space-y-4 mb-6", children: [_jsx(TextField, { value: identData.email, onChange: function (e) {
                                        setValue("identifiable_info.".concat(item), e.target.value);
                                        if (e.target.value === identData.email) {
                                            setSameEmail(true);
                                        }
                                        else {
                                            setSameEmail(false);
                                        }
                                    }, label: tolgee.t("dsr.settings.ccpa.default_data.".concat(item)), error: !!((_a = errors.identifiable_info) === null || _a === void 0 ? void 0 : _a[item]), helperText: (_c = (_b = errors.identifiable_info) === null || _b === void 0 ? void 0 : _b[item]) === null || _c === void 0 ? void 0 : _c.message, required: true }), _jsxs("div", { className: "flex space-x-2 mt-6", children: [_jsx(Checkbox, { checked: sameEmail, style: { padding: 0, margin: 0, marginRight: 2 }, onChange: handleCheckboxChange }), _jsx("span", { children: _jsx(T, { keyName: "dsr.use_the_same_email" }) })] })] })), item === "address" && (_jsx("div", { className: "flex flex-col space-y-4 mb-6", children: _jsx(AddressForm, { contactAddresses: [identData.address], validationError: !!((_d = errors.identifiable_info) === null || _d === void 0 ? void 0 : _d[item]), onChange: function (val) { return setValue("identifiable_info.address", val[0]); }, required: true, shrink: true }) })), ["name", "phone", "customer_number", "purchase_number", "pseudonym", "other"].includes(item) && (_jsx("div", { className: "flex flex-col space-y-4 mb-6", children: _jsx(TextField, { value: identData[item], onChange: function (e) {
                                    setValue("identifiable_info.".concat(item), e.target.value);
                                }, label: tolgee.t("dsr.settings.ccpa.default_data.".concat(item)), error: !!((_e = errors.identifiable_info) === null || _e === void 0 ? void 0 : _e[item]), helperText: (_g = (_f = errors.identifiable_info) === null || _f === void 0 ? void 0 : _f[item]) === null || _g === void 0 ? void 0 : _g.message, required: true, InputLabelProps: {
                                    shrink: true,
                                } }) }))] }, item));
            })] }));
}
