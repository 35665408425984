import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import InternalCommentModal from "pages/Client/Case/DSR/ClientView/Components/InternalCommentModal";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData } from "store/thunks";
import EmailsOverviewModal from "pages/Client/Case/DSR/ClientView/Components/EmailsOverviewModal";
import { generateI18nKeyByCaseType } from "helpers/case";
export default function DSRMenu(_a) {
    var isRequestClosed = _a.isRequestClosed;
    var _b = useCase(), state = _b[0], dispatch = _b[1];
    var _c = useState(false), openCommentModal = _c[0], setOpenCommentModal = _c[1];
    var _d = useState(false), openEmailsModal = _d[0], setOpenEmailsModal = _d[1];
    var _e = useState(null), anchorEl = _e[0], setAnchorEl = _e[1];
    var open = Boolean(anchorEl);
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var onReload = function () {
        fetchCaseData()(dispatch, state.case.uuid);
        handleClose();
    };
    return (_jsxs("div", { children: [_jsx(IconButton, { className: "w-12 h-12", onClick: handleClick, children: _jsx(FontAwesomeIcon, { icon: "ellipsis-v" }) }), _jsxs(Menu, { id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    "aria-labelledby": "basic-button",
                }, children: [_jsx(MenuItem, { onClick: function () { return setOpenEmailsModal(true); }, children: tolgee.t({
                            key: generateI18nKeyByCaseType(state.case.type, "emails_overview"),
                        }) }), !isRequestClosed ? (_jsx(MenuItem, { onClick: function () { return setOpenCommentModal(true); }, children: tolgee.t({
                            key: generateI18nKeyByCaseType(state.case.type, "internal_comment"),
                        }) })) : (""), _jsx(MenuItem, { onClick: function () {
                            fetchCaseData()(dispatch, state.case.uuid);
                            handleClose();
                        }, children: tolgee.t({
                            key: generateI18nKeyByCaseType(state.case.type, "reload"),
                        }) }), _jsx(MenuItem, { component: "a", href: state.case.public_url, target: "_blank", rel: "noopener noreferrer", children: tolgee.t({
                            key: generateI18nKeyByCaseType(state.case.type, "public_url"),
                        }) })] }), !isRequestClosed ? (_jsx(InternalCommentModal, { internalComment: true, handleReload: onReload, modalState: [openCommentModal, setOpenCommentModal] })) : (""), _jsx(EmailsOverviewModal, { modalState: [openEmailsModal, setOpenEmailsModal] })] }));
}
