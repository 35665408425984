var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { BaseButton, BaseIconButton } from "components";
import tolgee from "services/translation";
import { api } from "services";
import { notify } from "store/app";
import { useAppDispatch } from "store";
import { getBeautyDate } from "helpers";
export default function TextCertificate() {
    var _this = this;
    var businessId = useParams().businessId;
    var dispatch = useAppDispatch();
    var _a = useForm(), register = _a.register, setValue = _a.setValue, getValues = _a.getValues, watch = _a.watch;
    var _b = useState([]), certificates = _b[0], setCertificates = _b[1];
    var _c = useState(0), reloadState = _c[0], setReload = _c[1];
    var reload = function () { return setReload(function (prev) { return prev + 1; }); };
    var fetchCertificates = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.getCertificateTexts(businessId)];
                case 1:
                    res = _a.sent();
                    setCertificates(res);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId]);
    useEffect(function () {
        fetchCertificates();
    }, [fetchCertificates, reloadState]);
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.addCertificateText(businessId, getValues())];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "clp.text_certificate_added",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    reload();
                    return [2 /*return*/];
            }
        });
    }); };
    var onDelete = function (id) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.deleteCertificateText(businessId, id)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "clp.text_certificate_deleted",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    reload();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "mt-12 mb-4", children: [_jsxs("div", { className: "grid grid-cols-[repeat(5,1fr)_auto]", children: [_jsx("b", { children: "Certificate" }), _jsx("b", { children: "Issuer" }), _jsx("b", { children: "Date Issued" }), _jsx("b", { children: "Valid Until" }), _jsx("b", { children: "Website" }), _jsx("div", { className: "w-8" })] }), certificates === null || certificates === void 0 ? void 0 : certificates.map(function (certificate) { return (_jsxs("div", { className: "grid grid-cols-[repeat(5,1fr)_auto]", children: [_jsx("div", { className: "break-all flex items-center", children: certificate.cert }), _jsx("div", { className: "break-all flex items-center", children: certificate.issuer }), _jsx("div", { className: "break-all flex items-center", children: getBeautyDate(certificate.issued_at) }), _jsx("div", { className: "break-all flex items-center", children: getBeautyDate(certificate.valid_until) }), _jsx("div", { className: "break-all flex items-center", children: certificate.url }), _jsx(BaseIconButton, { confirmPopup: true, className: "self-center", onClick: function () { return onDelete(certificate.uuid); }, size: "medium", children: _jsx(FontAwesomeIcon, { icon: "trash", color: "red", size: "sm" }) })] })); })] }), _jsxs(Stack, { spacing: 2, children: [_jsx("div", { children: _jsx(T, { keyName: "clp.present_certificates" }) }), _jsxs("div", { className: "flex space-x-4", children: [_jsx(TextField, __assign({}, register("cert"), { value: watch("cert"), className: "w-full", label: tolgee.t({
                                    key: "clp.certificate",
                                }) })), _jsx(TextField, __assign({}, register("issuer"), { className: "w-full", label: tolgee.t({
                                    key: "clp.issuer",
                                }) }))] }), _jsxs("div", { className: "flex space-x-4", children: [_jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { format: "DD/MM/YYYY", onChange: function (val) { return setValue("issued_at", val.toISOString()); }, label: tolgee.t({ key: "clp.date_issued" }), className: "w-full" }) }), _jsx(LocalizationProvider, { dateAdapter: AdapterMoment, children: _jsx(DatePicker, { format: "DD/MM/YYYY", onChange: function (val) { return setValue("valid_until", val.toISOString()); }, label: tolgee.t({ key: "clp.valid_until" }), className: "w-full" }) })] }), _jsx(TextField, __assign({}, register("url"), { label: tolgee.t({
                            key: "clp.website",
                        }), className: "w-full" })), _jsx(BaseButton, { onClick: onSubmit, className: "self-end", children: _jsx(T, { keyName: "clp.add_certificate" }) })] })] }));
}
