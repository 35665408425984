import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useState } from "react";
import { useParams } from "react-router";
import { BaseButton } from "components/Button";
import { BaseModal } from "components/DataDisplay/Modal";
import { SupportMessageType } from "types/message";
import SupportMessage from "components/Support/SupportMessage";
import tolgee from "services/translation";
export default function BetaProgram() {
    var businessId = useParams().businessId;
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    return (_jsxs("div", { className: "mt-4 mb-4 box-outerlayout", children: [_jsx("h2", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.beta_title" }) }), _jsx("p", { className: "m-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.beta_description" }) }), _jsxs("div", { className: "box-dotted mt-4 mb-4", children: [_jsx("p", { className: "m-0 font-bold", children: _jsx(T, { keyName: "dsr.settings.ccpa.beta_identify_title" }) }), _jsx("p", { className: "m-0", children: _jsx(T, { keyName: "dsr.settings.ccpa.beta_identify_description" }) })] }), _jsx("div", { className: "flex items-start", children: _jsx(BaseButton, { className: "w-auto", variant: "outlined", onClick: function () { return setOpen(true); }, children: _jsx(T, { keyName: "dsr.settings.ccpa.beta_join_program" }) }) }), _jsx(BaseModal, { modalState: [open, setOpen], children: _jsx(SupportMessage, { businessId: businessId, supportMessageType: SupportMessageType.CCPA_CONFIG_LOGIN, initialContent: "I'm interested in using our own login for data subject verification", modalTitle: tolgee.t("dsr.settings.ccpa.beta_title"), contentPlaceholder: tolgee.t("general.type_placeholder"), openSupportModal: function (value) { return setOpen(value); } }) })] }));
}
