import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import AddProcessorField from "pages/Client/Case/DSR/ClientView/Components/AddProcessorField";
import tolgee from "services/translation";
import { useCase } from "pages/Client/Case/CaseContext";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { isCheckFinished, generateI18nKey } from "helpers/case";
export default function AddProcessors() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var handleReload = useCallback(function () { return fetchCaseData()(dispatch, caseData.uuid, false); }, [dispatch, caseData]);
    var requestClosed = caseData.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    return (_jsxs(_Fragment, { children: [tolgee.t({
                key: generateI18nKey(caseData.type, state.ui.check, "description"),
            }), _jsxs("div", { className: "flex flex-row space-x-2 justify-end", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), children: tolgee.t({
                            key: "general.reopen",
                        }) })), _jsx(Button, { "data-testid": "confirmProcessorsAdded", disabled: isFinished, onClick: function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check); }, color: "success", children: tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, "confirm_processors_added"),
                        }) })] }), _jsx("span", { className: "mb-2", children: tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "description_add_processor_contractor"),
                }) }), _jsx(AddProcessorField, { caseActors: caseData.case_actors, handleReload: handleReload, locked: !!isFinished, contactType: "PROCESSOR" })] }));
}
