import { createSlice } from "@reduxjs/toolkit";
export var initialState = [];
export var menuSlice = createSlice({
    name: "menu",
    initialState: initialState,
    reducers: {
        setMenu: function (previous_state, action) {
            return action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
export var setMenu = menuSlice.actions.setMenu;
export default menuSlice.reducer;
