import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseSelectField } from "components/Input";
import { BaseIconButton } from "components/Button";
import { generateRopaRoute, getBusinessId, serializeRopaRoute } from "helpers/ropa/route";
export default function RopaNavbar() {
    var location = useLocation();
    var navigate = useNavigate();
    var _a = useState({
        navItem: "persons",
    }), historyState = _a[0], setHistoryState = _a[1];
    useEffect(function () {
        var history = serializeRopaRoute(location.pathname);
        if (history) {
            setHistoryState(history);
        }
    }, [location]);
    var tabs = {
        persons: "Persons",
        processes: "Processes",
        "data-categories": "Data Categories",
        "affected-groups": "Affected Groups",
        controllers: "Controllers",
        "internal-recipients": "Internal Recipients",
        "external-recipients": "External Recipients",
        "processor-recipients": "Processor Recipients",
    };
    var handleChangeTab = function (newNav) {
        var history = {
            businessId: getBusinessId(),
            navItem: newNav,
            page: "list",
        };
        setHistoryState(history);
        navigate(generateRopaRoute(history));
    };
    return (_jsx("div", { className: "!p-2 box-outerlayout mb-4", children: _jsxs("div", { className: "flex items-center space-x-4", children: [_jsx("div", { className: "w-8", children: historyState.page !== "list" && (_jsx(BaseIconButton, { onClick: function () { return navigate(-1); }, testid: "back-button", children: _jsx(FontAwesomeIcon, { icon: "arrow-left" }) })) }), _jsx("div", { className: "w-56", children: _jsx(BaseSelectField, { state: [historyState.navItem, handleChangeTab], options: tabs }) }), historyState.page !== "list" && (_jsxs(_Fragment, { children: [_jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "2xl" }), historyState.page === "create" && _jsx("b", { className: "text-lg", children: "Create" }), historyState.page === "detail" && _jsx("b", { className: "text-lg", children: "Detail" })] }))] }) }));
}
