import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, } from "@mui/material";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { T } from "@tolgee/react";
import { getComparator, stableSort } from "components/DataDisplay/Table/EnhancedTable/helpers";
import EnhancedTableHead from "components/DataDisplay/Table/EnhancedTable/TableHead";
import { useDebounce } from "helpers/hooks";
import BaseModal from "components/DataDisplay/Modal/BaseModal";
import TableSearch from "components/Input/TextField/Custom/SearchField";
import { ArrayWithIndex } from "helpers/general";
export default function EnhancedTable(props) {
    var _a, _b;
    var config = props.config, showPagination = props.showPagination, onPageChange = props.onPageChange, onRowsPerPageChange = props.onRowsPerPageChange, onOrderChange = props.onOrderChange, count = props.count, hideEmptyRows = props.hideEmptyRows, loading = props.loading;
    var values = config.values, keys = config.keys, settings = config.settings;
    var _c = useState([]), filteredValues = _c[0], setFilteredValues = _c[1];
    var navigate = useNavigate();
    var _d = useState("asc"), order = _d[0], setOrder = _d[1]; // TODO check if you can do a customHook with the 5 state below, also less useState means more performance.
    var _e = useState(""), orderBy = _e[0], setOrderBy = _e[1];
    var _f = useState(0), page = _f[0], setPage = _f[1];
    var _g = useState(25), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = useState(""), search = _h[0], setSearch = _h[1];
    var debouncedValue = useDebounce(search, 500);
    var _j = useState(false), open = _j[0], setOpen = _j[1]; // modal state
    var _k = useState(_jsx(_Fragment, {})), modalChildren = _k[0], setModalChildren = _k[1]; // modal state
    useEffect(function () {
        var _a;
        if ((_a = settings.defaultSort) === null || _a === void 0 ? void 0 : _a.key) {
            setOrderBy(settings.defaultSort.key);
            setOrder(settings.defaultSort.order || "desc");
        }
    }, [settings.defaultSort]);
    useEffect(function () {
        setFilteredValues(values);
    }, [values]);
    useEffect(function () {
        if (debouncedValue && debouncedValue !== "") {
            setFilteredValues(values.filter(function (value) {
                return Object.values(value.cells).some(function (cellVal) {
                    var _a;
                    return (_a = cellVal.displayedString) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(debouncedValue === null || debouncedValue === void 0 ? void 0 : debouncedValue.toLowerCase());
                });
            }));
        }
        else if (debouncedValue === "") {
            setFilteredValues(values);
        }
    }, [values, debouncedValue, setFilteredValues]);
    var handleRequestSort = function (event, property) {
        if (onOrderChange) {
            onOrderChange(property);
            setOrderBy(property);
            setOrder("desc");
        }
        else {
            var isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        }
    };
    var handleChangePage = function (event, newPage) {
        setPage(newPage);
        onPageChange(newPage);
    };
    var handleChangeRowsPerPage = function (event) {
        var newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        onRowsPerPageChange(newRowsPerPage);
    };
    var handleClick = function (id) {
        var _a;
        var actionCell = (_a = settings === null || settings === void 0 ? void 0 : settings.onClickEvents) === null || _a === void 0 ? void 0 : _a.find(function (act) { return act.id === id; });
        if (actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl) {
            if (actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl.includes("http")) {
                window.location.assign(actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl);
            }
            else {
                navigate(actionCell === null || actionCell === void 0 ? void 0 : actionCell.actionUrl);
            }
        }
        else if ((settings === null || settings === void 0 ? void 0 : settings.onClickType) === "MODAL") {
            setModalChildren(actionCell === null || actionCell === void 0 ? void 0 : actionCell.modalElement);
            setOpen(true);
        }
    };
    var sortList = function () {
        return !settings.disableSort && orderBy
            ? stableSort(filteredValues, getComparator(order, orderBy))
            : filteredValues;
    };
    var adjustedSortList = function () {
        return showPagination
            ? sortList().slice(!showPagination && page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : sortList();
    };
    var loadingState = function () {
        return (_jsxs("div", { className: "p-6", children: [_jsx(Skeleton, { animation: "wave" }), _jsx(Skeleton, { animation: "wave" }), _jsx(Skeleton, { animation: "wave" })] }));
    };
    var emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredValues.length - page * rowsPerPage);
    emptyRows -= filteredValues.length === 0 && 1;
    if (values.length === 0) {
        emptyRows = 5;
    }
    var columnSize = ((_a = settings.action) === null || _a === void 0 ? void 0 : _a.headTitle) ? keys.length : keys.length - 1;
    return (_jsxs("div", { className: "w-full mb-5", children: [_jsx(TableSearch, { searchState: [search, setSearch] }), _jsxs(Paper, { className: "w-full -mb-4", children: [_jsx(TableContainer, { children: _jsxs(Table, { style: { minWidth: "750px", minHeight: "360px" }, "aria-labelledby": "tableTitle", "aria-label": "enhanced table", children: [_jsx(EnhancedTableHead, { actionTitle: (_b = settings.action) === null || _b === void 0 ? void 0 : _b.headTitle, keys: keys.filter(function (k) { var _a; return !((_a = settings === null || settings === void 0 ? void 0 : settings.ignoreFields) === null || _a === void 0 ? void 0 : _a.includes(k)); }), shouldSort: !(settings === null || settings === void 0 ? void 0 : settings.disableSort), order: order, orderBy: orderBy, onRequestSort: handleRequestSort, noTableHead: settings.noTableHead }), _jsxs(TableBody, { children: [adjustedSortList().map(function (row) {
                                            var _a, _b, _c, _d, _e;
                                            var rowValues = Object.entries(row.cells);
                                            var actionCell = (_d = (_c = (_b = (_a = config.settings) === null || _a === void 0 ? void 0 : _a.action) === null || _b === void 0 ? void 0 : _b.actionElements) === null || _c === void 0 ? void 0 : _c.find(function (act) { return act.id === row.id; })) === null || _d === void 0 ? void 0 : _d.action;
                                            return (_jsxs(TableRow, { "data-testid": "table-row", hover: !!(settings === null || settings === void 0 ? void 0 : settings.onClickType), tabIndex: 0, className: clsx({
                                                    "cursor-pointer": settings === null || settings === void 0 ? void 0 : settings.onClickType,
                                                }), style: { height: "60px" }, children: [rowValues.map(function (_a) {
                                                        var _b;
                                                        var key = _a[0], value = _a[1];
                                                        return !((_b = settings === null || settings === void 0 ? void 0 : settings.ignoreFields) === null || _b === void 0 ? void 0 : _b.includes(key)) && (_jsx(TableCell, { onClick: function () { return handleClick(row.id); }, children: value.displayedElement || value.displayedString }, key));
                                                    }), actionCell && (_jsx(TableCell, { align: "right", padding: "none", style: { width: (_e = settings === null || settings === void 0 ? void 0 : settings.action) === null || _e === void 0 ? void 0 : _e.actionColumnWidth }, children: actionCell }))] }, row.id));
                                        }), loading && loadingState(), filteredValues.length === 0 && columnSize !== -1 && (_jsxs(TableRow, { hover: true, style: { height: "60px" }, children: [_jsx(TableCell, { children: _jsx(T, { keyName: "generic.table.empty_message" }) }), ArrayWithIndex(columnSize).map(function (index) { return (_jsx(TableCell, {}, index)); })] })), !hideEmptyRows && emptyRows > 0 && (_jsx(TableRow, { style: { height: 60 * emptyRows }, children: _jsx(TableCell, { colSpan: 6 }) }))] })] }) }), showPagination && (_jsx(TablePagination, { style: { backgroundColor: "#d9d9d9" }, rowsPerPageOptions: [25, 50, 100], component: "div", count: count || filteredValues.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }))] }), settings.onClickType === "MODAL" && (_jsx(BaseModal, { modalState: [open, setOpen], children: modalChildren }))] }));
}
