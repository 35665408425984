import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import DeclineFraudulentButton from "pages/Client/Case/DSR/ClientView/DeclineFraudulentButton";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import DeclineWithPreviewButton from "pages/Client/Case/DSR/ClientView/DeclineWithPreviewButton";
import tolgee from "services/translation";
import { finalizeCheck, reopenCheck } from "store/thunks";
export default function ConfirmInquiry(_a) {
    var handleSendInquiry = _a.handleSendInquiry, handleComplete = _a.handleComplete, confirmButtonText = _a.confirmButtonText, declinePreview = _a.declinePreview, _b = _a.inquiry, inquiry = _b === void 0 ? true : _b;
    var _c = useCase(), state = _c[0], dispatch = _c[1];
    var caseData = state.case;
    var caseClosed = caseData.state === "CLOSED";
    var completeCheck = handleComplete || (function () { return finalizeCheck()(dispatch, caseData.uuid, state.ui.check); });
    var isFinished = isCheckFinished(state);
    return (_jsxs("div", { className: "flex flex-row justify-between", children: [_jsxs("div", { className: "space-x-2", children: [inquiry && caseData.type !== "DSR_CCPA_LIMIT" && caseData.type !== "DSR_CCPA_OPTOUT" && (_jsx(Button, { onClick: handleSendInquiry, disabled: isFinished, children: tolgee.t({
                            key: generateI18nKey(caseData.type, state.ui.check, "send_inquiry"),
                        }) })), !caseClosed &&
                        !isFinished &&
                        declinePreview &&
                        (declinePreview === "FRAUDULENT" ? (_jsx(DeclineFraudulentButton, { label: generateI18nKey(caseData.type, state.ui.check, "decline_open"), labelSubmit: generateI18nKey(caseData.type, state.ui.check, "decline_submit"), disabled: isFinished, declineType: declinePreview })) : (_jsx(DeclineWithPreviewButton, { label: generateI18nKey(caseData.type, state.ui.check, "decline_open"), labelSubmit: generateI18nKey(caseData.type, state.ui.check, "decline_submit"), disabled: isFinished, declineType: declinePreview })))] }), _jsxs("div", { children: [_jsx(Button, { onClick: completeCheck, disabled: isFinished, "data-testid": "dsr_confirmation_button", sx: {
                            backgroundColor: "#4ad158",
                            "&:hover": { backgroundColor: "#4b904f" },
                        }, children: confirmButtonText ||
                            tolgee.t({
                                key: generateI18nKey(caseData.type, state.ui.check, "confirm"),
                            }) }), isFinished && !caseClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: " !text-sm" }), sx: {
                            ml: 2,
                        }, children: tolgee.t({
                            key: "general.reopen",
                        }) }))] })] }));
}
