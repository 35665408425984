var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
export default function SplitButton(_a) {
    var _b, _c, _d;
    var items = _a.items, _e = _a.listHeight, listHeight = _e === void 0 ? "190px" : _e, state = _a.state, _f = _a.disabled, disabled = _f === void 0 ? false : _f, _g = _a.loading, loading = _g === void 0 ? false : _g, buttonPreName = _a.buttonPreName, color = _a.color;
    var _h = useState(false), open = _h[0], setOpen = _h[1];
    var anchorRef = useRef(null);
    var selectedIndex = state[0], setSelectedIndex = state[1];
    var handleClick = function () {
        items[selectedIndex].action();
    };
    var handleMenuItemClick = function (event, index) {
        setSelectedIndex(index);
        items[index].action();
        setOpen(false);
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ButtonGroup, { variant: "contained", ref: anchorRef, "aria-label": "split button", children: [_jsxs(Button, { disabled: disabled, onClick: handleClick, color: color, children: [((_b = items[selectedIndex]) === null || _b === void 0 ? void 0 : _b.icon) && (_jsx(FontAwesomeIcon, { className: "mr-3", size: "lg", icon: (_c = items[selectedIndex]) === null || _c === void 0 ? void 0 : _c.icon, color: "white" })), buttonPreName, (_d = items[selectedIndex]) === null || _d === void 0 ? void 0 : _d.name] }), _jsx(Button, { color: color, disabled: disabled, size: "small", "aria-controls": open ? "split-button-menu" : undefined, "aria-expanded": open ? "true" : undefined, "aria-label": "select merge strategy", "aria-haspopup": "menu", onClick: handleToggle, children: loading ? _jsx(CircularProgress, { size: "1.5rem" }) : _jsx(FontAwesomeIcon, { icon: "caret-down" }) })] }), _jsx(Popper, { color: color, sx: {
                    zIndex: 1,
                }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, placement: "bottom-end", disablePortal: true, children: function (_a) {
                    var TransitionProps = _a.TransitionProps, placement = _a.placement;
                    return (_jsx(Grow, __assign({}, TransitionProps, { style: {
                            transformOrigin: placement.includes("bottom") ? "center top" : "center bottom",
                        }, children: _jsx(Paper, { children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(MenuList, { id: "split-button-menu", autoFocusItem: true, style: {
                                        maxHeight: listHeight, // Adjust the height as needed
                                        overflowY: "auto", // Add vertical scrollbar
                                    }, children: items.map(function (item, index) { return (_jsx(MenuItem, { selected: index === selectedIndex, onClick: function (event) { return handleMenuItemClick(event, index); }, children: _jsxs("div", { className: "flex items-center ".concat(index !== selectedIndex && "ml-6 pl-1"), children: [index === selectedIndex && (_jsx(FontAwesomeIcon, { className: "mr-3", size: "lg", icon: "check", color: "gray" })), _jsxs("div", { className: "flex flex-col ".concat((item === null || item === void 0 ? void 0 : item.details) && "mb-2"), children: [_jsx("div", { children: item.name }), (item === null || item === void 0 ? void 0 : item.details) && _jsx("div", { className: "opacity-50", children: item.details })] })] }) }, index)); }) }) }) }) })));
                } })] }));
}
