var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { BaseIconButton, FormFileField } from "components";
import { generateI18nKey, generateUrl, isCheckFinished } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import tolgee from "services/translation";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import { api } from "services";
import { notify } from "store/app";
import { getFileIcon, getFileName } from "helpers";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
export default function DSDataUpload() {
    var _this = this;
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var appDispatch = useDispatch();
    var requestClosed = state.case.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    var _b = useState(false), uploading = _b[0], setUploading = _b[1];
    var handleUploadFiles = function (filesToUpload) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setUploading(true);
                    return [4 /*yield*/, api.authCase.uploadFileToCase(state.case.uuid, filesToUpload, "DS_DATA")];
                case 1:
                    res = _a.sent();
                    setUploading(false);
                    if (res) {
                        appDispatch(notify({ message: "Files uploaded successfully", type: "SUCCESS" }));
                        fetchCaseData()(dispatch, state.case.uuid, false);
                    }
                    else {
                        appDispatch(notify({ message: "Error uploading files", type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleRemoveFile = function (file) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.authCase.removeFileFromCase(state.case.uuid, file.uuid)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        appDispatch(notify({ message: "File removed successfully", type: "SUCCESS" }));
                        fetchCaseData()(dispatch, state.case.uuid, false);
                    }
                    else {
                        appDispatch(notify({ message: "Error removing file", type: "ERROR" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleFileChange = function (selectedFiles) {
        if (uploading) {
            return;
        } // Prevent double upload
        if (selectedFiles.length > 0) {
            handleUploadFiles(selectedFiles);
        }
    };
    var handleCloseCheck = function () {
        finalizeCheck()(dispatch, state.case.uuid, state.ui.check);
    };
    return (_jsxs("div", { className: "flex flex-col items-start space-y-4", children: [_jsx("span", { children: tolgee.t({
                    key: generateI18nKey(state.case.type, state.ui.check, "description"),
                }) }), _jsx(FormFileField, { disabled: isFinished || requestClosed, label: "Upload a file", className: "self-center", onChange: handleFileChange, clearFilesAfterUpload: true, multiple: true, required: true }), _jsxs("div", { children: [state.case.files.filter(function (file) { return file.type === "DS_DATA"; }).length > 0 && (_jsx("div", { children: tolgee.t({
                            key: generateI18nKey(state.case.type, state.ui.check, "uploaded_files"),
                        }) })), state.case.files
                        .filter(function (file) { return file.type === "DS_DATA"; })
                        .map(function (file) { return (_jsxs("div", { className: "flex flex-row space-x-2", children: [_jsx("div", { className: "pt-2.5", children: _jsx(BaseIconButton, { disabled: isFinished || requestClosed, onClick: function () { return handleRemoveFile(file); }, className: "w-5 h-5", size: "medium", children: _jsx(FontAwesomeIcon, { size: "xs", icon: "xmark", color: "danger" }) }) }), _jsx(FileDownloadPreview, { icon: getFileIcon(file.mime_type), url: generateUrl(state.case.uuid, file.uuid), fileName: getFileName(file), mimeType: file.mime_type })] }, file.uuid)); })] }), _jsxs("div", { className: "flex w-full justify-end space-x-2", children: [_jsx(Button, { onClick: handleCloseCheck, "data-testid": "confirmDataUpload", disabled: isFinished || uploading, children: tolgee.t({
                            key: generateI18nKey(state.case.type, state.ui.check, "confirm"),
                        }) }), isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, state.case.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), sx: {
                            ml: 2,
                        }, children: tolgee.t({
                            key: "general.reopen",
                        }) }))] })] }));
}
