var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "store";
import NavMenu from "components/Layout/Navbar/NavMenu";
import ProgressBar from "components/Feedback/ProgressBar";
import { extractBusinessIdsFromNav } from "helpers/general";
import { changePreference } from "store/app";
import { fetchMenuData } from "store/thunks";
function NavBar() {
    var location = useLocation();
    var businessId = useParams().businessId;
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var dispatch = useAppDispatch();
    var previouslySelectedBusiness = useAppSelector(function (state) { return state.app.preferences; }).selectedBusiness;
    var menu = useAppSelector(function (state) { return state.menu; });
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var hasRun = useRef(false);
    var _b = useState(""), selectedBusiness = _b[0], setSelectedBusiness = _b[1];
    var _c = useState([]), businesses = _c[0], setBusinesses = _c[1];
    useEffect(function () {
        var _a, _b;
        if (!hasRun.current && menu.length > 0) {
            var ids = [];
            if (managed_businesses.length === 0) {
                var businessIds = extractBusinessIdsFromNav(menu);
                ids = businessIds.map(function (business) { return ({
                    name: business,
                    id: business,
                }); });
            }
            else {
                ids = managed_businesses.map(function (business) { return ({
                    name: business.company_name,
                    id: business.public_id,
                }); });
            }
            setBusinesses(ids);
            if (previouslySelectedBusiness && ids.find(function (id) { return id.id === previouslySelectedBusiness; })) {
                setSelectedBusiness(previouslySelectedBusiness);
            }
            else {
                setSelectedBusiness((_a = ids[0]) === null || _a === void 0 ? void 0 : _a.id);
                dispatch(changePreference({ selectedBusiness: (_b = ids[0]) === null || _b === void 0 ? void 0 : _b.id }));
            }
            hasRun.current = true;
        }
    }, [dispatch, managed_businesses, menu, previouslySelectedBusiness]);
    useEffect(function () {
        if (businessId) {
            setSelectedBusiness(businessId);
            dispatch(changePreference({ selectedBusiness: businessId }));
        }
    }, [businessId, dispatch]);
    useEffect(function () {
        var retryCount = 0;
        function getRoutes() {
            return __awaiter(this, void 0, void 0, function () {
                var retryDelay;
                return __generator(this, function (_a) {
                    setLoading(true);
                    if (menu.length > 0) {
                        setLoading(false);
                        retryCount = 0;
                    }
                    else {
                        retryDelay = void 0;
                        if (retryCount <= 2) {
                            retryDelay = 1000 * 2;
                        }
                        else {
                            retryDelay = 1000 * 60 * 3;
                        }
                        retryCount += 1;
                        setTimeout(function () {
                            getRoutes();
                        }, retryDelay);
                    }
                    return [2 /*return*/];
                });
            });
        }
        getRoutes();
        var interval = setInterval(function () {
            getRoutes();
        }, 1000 * 60 * 2);
        return function () {
            clearInterval(interval);
        };
    }, [menu]);
    useEffect(function () {
        dispatch(fetchMenuData());
    }, [location]);
    return (_jsxs("div", { className: "max-w-sm pt-1", children: [menu.map(function (men, ind) { return (_jsx(NavMenu, { navMenu: men, businesses: businesses, selectedBusiness: selectedBusiness, setSelectedBusiness: setSelectedBusiness }, ind)); }), menu.length === 0 && loading && _jsx(ProgressBar, {})] }));
}
export default NavBar;
