var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useCase } from "pages/Client/Case/CaseContext";
import { useDebounce } from "helpers/hooks";
import { api } from "services";
import tolgee from "services/translation";
import ContactModal from "pages/Client/ContactDirectory/Components/ContactModal";
import { generateI18nKey } from "helpers/case";
export default function AddControllerField(data) {
    var _this = this;
    var handleAddAndClose = data.handleAddAndClose, locked = data.locked;
    var state = useCase()[0];
    var caseData = state.case;
    var checkStep = state.ui.check;
    var _a = useState(["create_contact"]), contactData = _a[0], setContactData = _a[1];
    var _b = useState(""), search = _b[0], setSearch = _b[1];
    var debouncedSearchValue = useDebounce(search, 500);
    var _c = useState(null), chosenContact = _c[0], setChosenContact = _c[1];
    var _d = useState(false), contactModal = _d[0], setContactModal = _d[1];
    function fetchContactEmailData(props) {
        return __awaiter(this, void 0, void 0, function () {
            var order_by, page, per_page, search_term, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        order_by = props.order_by, page = props.page, per_page = props.per_page, search_term = props.search_term;
                        return [4 /*yield*/, api.contactDirectory.getContacts({
                                order_by: order_by,
                                page: page,
                                per_page: per_page,
                                search_term: search_term,
                            })];
                    case 1:
                        res = _a.sent();
                        if (res) {
                            setContactData(__spreadArray(["create_contact"], res.result, true));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        fetchContactEmailData({
            order_by: "person_name",
            page: 1,
            per_page: 100,
            search_term: debouncedSearchValue,
        });
    }, [debouncedSearchValue]);
    var generateLabel = function (option) {
        var _a, _b;
        if (option === "create_contact") {
            return tolgee.t({
                key: generateI18nKey(caseData.type, checkStep, "create_contact"),
            });
        }
        var result = [];
        result.push((_a = option.person_name) === null || _a === void 0 ? void 0 : _a.trim());
        result.push((_b = option.organization_name) === null || _b === void 0 ? void 0 : _b.trim());
        var label = result.filter(function (value) { return value; }).join(" / ");
        var email = option.emails.length ? "(".concat(option.emails[0], ")") : "";
        return "".concat(label, " ").concat(email);
    };
    var handleAddController = function (newContact) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (newContact === "create_contact") {
                setContactModal(true);
            }
            else {
                setChosenContact(newContact);
            }
            return [2 /*return*/];
        });
    }); };
    var handleChangeController = function (_, __, reason, details) {
        if (reason === "selectOption") {
            handleAddController(details.option);
        }
    };
    var handleCloseModal = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setSearch("");
                    return [4 /*yield*/, fetchContactEmailData({
                            order_by: "person_name",
                            page: 1,
                            per_page: 100,
                            search_term: "",
                        })];
                case 1:
                    _a.sent();
                    setContactModal(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: "whitespace-pre-line", children: tolgee.t({
                    key: generateI18nKey(caseData.type, checkStep, "add_controller_and_close_title"),
                }) }), _jsx(Autocomplete, { disabled: locked, disableClearable: true, options: contactData, style: { marginTop: "10px" }, getOptionLabel: function (option) { return generateLabel(option); }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: tolgee.t({
                        key: generateI18nKey(caseData.type, checkStep, "create_controller"),
                    }), required: true, variant: "outlined" }))); }, onChange: handleChangeController, inputValue: search, onInputChange: function (_, newSearch) {
                    setSearch(newSearch);
                } }), _jsx("div", { className: "self-end mt-4", children: _jsx(Button, { onClick: function () { return handleAddAndClose(chosenContact.uuid); }, children: tolgee.t({
                        key: generateI18nKey(caseData.type, checkStep, "add_controller_and_handover"),
                    }) }) }), _jsx(ContactModal, { modalState: [contactModal, handleCloseModal], emailRequired: true })] }));
}
