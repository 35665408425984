var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ManageBusinessHeader from "pages/Admin/ManageUser/ManageBusiness/Header";
import { api } from "services";
import { BaseTabs } from "components/Tabs/Tabs";
import ConfigTab from "pages/Admin/ManageUser/ManageBusiness/ConfigTab";
import SubscriptionsTab from "pages/Admin/ManageUser/ManageBusiness/SubscriptionsTab";
import RepresentativesTab from "pages/Admin/ManageUser/ManageBusiness/RepresentativesTab";
import RopaTab from "pages/Admin/ManageUser/ManageBusiness/RopaTab";
import LandingPageSettings from "pages/Client/LandingPage/View";
export default function ManageBusiness() {
    var businessId = useParams().businessId;
    var _a = useState({}), business = _a[0], setBusiness = _a[1];
    var _b = useState(0), reload = _b[0], increaseReload = _b[1];
    var handleReload = function () {
        increaseReload(function (prev) { return prev + 1; });
    };
    useEffect(function () {
        function fetchBusiness() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.business.get(businessId)];
                        case 1:
                            res = _a.sent();
                            setBusiness(res);
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchBusiness();
    }, [businessId, reload]);
    var tabsData = [
        {
            id: 1,
            label: "Config",
            content: _jsx(ConfigTab, {}),
        },
        {
            id: 2,
            label: "Subscriptions",
            content: _jsx(SubscriptionsTab, { business: business }),
        },
        {
            id: 3,
            label: "Representatives",
            content: _jsx(RepresentativesTab, { business: business }),
        },
        {
            id: 4,
            label: "RoPAs",
            content: _jsx(RopaTab, { business: business }),
        },
        {
            id: 5,
            label: "Landing Page",
            content: _jsx(LandingPageSettings, { noTitle: true }),
        },
    ];
    return (_jsx("div", { children: business.public_id && (_jsxs(_Fragment, { children: [_jsx(ManageBusinessHeader, { business: business, handleReload: handleReload }), _jsx("div", { className: "mt-4", children: _jsx(BaseTabs, { tabsData: tabsData, defaultTab: 0 }) })] })) }));
}
