var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { T } from "@tolgee/react";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormFileField } from "components";
import tolgee from "services/translation";
import { api } from "services";
import { fileToFileObject } from "helpers";
import { useAppDispatch } from "store";
import { notify } from "store/app";
import FileDownloadPreview from "components/DataDisplay/FileDownloadPreview";
import { generateFileUrl } from "helpers/case";
export default function RopaTab(_a) {
    var _this = this;
    var business = _a.business;
    var _b = useState(), ropaController = _b[0], setRopaController = _b[1];
    var _c = useState(), ropaProcessor = _c[0], setRopaProcessor = _c[1];
    var _d = useState(0), reload = _d[0], setReload = _d[1];
    var dispatch = useAppDispatch();
    var handleReload = function () { return setReload(function (prevRel) { return prevRel + 1; }); };
    useEffect(function () {
        function fetchRopaFiles() {
            return __awaiter(this, void 0, void 0, function () {
                var res, res2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.business.getFile(business.public_id, "ROPA_CONTROLLER")];
                        case 1:
                            res = _a.sent();
                            setRopaController(res);
                            return [4 /*yield*/, api.business.getFile(business.public_id, "ROPA_PROCESSOR")];
                        case 2:
                            res2 = _a.sent();
                            setRopaProcessor(res2);
                            return [2 /*return*/];
                    }
                });
            });
        }
        fetchRopaFiles();
    }, [business.public_id, reload]);
    var handleUploadFile = function (file, type) { return __awaiter(_this, void 0, void 0, function () {
        var fileObject, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fileToFileObject(file)];
                case 1:
                    fileObject = _a.sent();
                    return [4 /*yield*/, api.business.uploadFile(business.public_id, fileObject, type)];
                case 2:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "ropa.file_uploaded",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    handleReload();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteFile = function (type) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.business.deleteFile(business.public_id, type)];
                case 1:
                    res = _a.sent();
                    if (res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "ropa.file_deleted",
                            }),
                            type: "SUCCESS",
                        }));
                    }
                    handleReload();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx("div", { className: "box-outerlayout", children: _jsxs("div", { className: "flex flex-row space-x-8", children: [_jsxs("div", { className: "flex flex-col", children: [_jsx("h3", { children: _jsx(T, { keyName: "manage_business.ropa_controller" }) }), _jsx(FormFileField, { label: tolgee.t({
                                key: "generic.choose_files",
                            }), onChange: function (files) { return handleUploadFile(files[0], "ROPA_CONTROLLER"); } }), ropaController && (_jsxs("div", { className: "flex items-start", children: [_jsx(FileDownloadPreview, { url: generateFileUrl(business.public_id, "ROPA_CONTROLLER"), fileName: tolgee.t({ key: "file.ropa_controller" }) }), _jsx(IconButton, { size: "medium", onClick: function () { return handleDeleteFile("ROPA_CONTROLLER"); }, children: _jsx(FontAwesomeIcon, { icon: "xmark", className: "text-gray-600", size: "xs" }) })] }))] }), _jsxs("div", { className: "flex flex-col", children: [_jsx("h3", { children: _jsx(T, { keyName: "manage_business.ropa_processor" }) }), _jsx(FormFileField, { label: tolgee.t({
                                key: "generic.choose_files",
                            }), onChange: function (files) { return handleUploadFile(files[0], "ROPA_PROCESSOR"); } }), ropaProcessor && (_jsxs("div", { className: "flex items-start", children: [_jsx(FileDownloadPreview, { url: generateFileUrl(business.public_id, "ROPA_PROCESSOR"), fileName: tolgee.t({ key: "file.ropa_processor" }) }), _jsx(IconButton, { size: "medium", onClick: function () { return handleDeleteFile("ROPA_PROCESSOR"); }, children: _jsx(FontAwesomeIcon, { icon: "xmark", className: "text-gray-600", size: "xs" }) })] }))] })] }) }));
}
