import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { SplitButton, VerticalDataValue } from "components";
export default function InvoicesTab(_a) {
    var user = _a.user;
    var _b = useState(0), itemIndex = _b[0], setItemIndex = _b[1];
    var createItems = [
        {
            name: "Email All Invoices",
            icon: "plus",
            action: function () { },
        },
    ];
    return (_jsxs("div", { className: "flex flex-col space-y-4", children: [_jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: "Quick Actions" }), _jsx("div", { children: _jsx(SplitButton, { items: createItems, listHeight: "424px", state: [itemIndex, setItemIndex] }) })] }), _jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: "Billing" }), _jsxs("div", { className: "grid grid-cols-[auto_auto_auto] grid-rows-[auto_auto_auto] gap-x-4 gap-y-1", children: [_jsx(VerticalDataValue, { title: "Billing Address", value: "-" }), _jsx("div", {}), _jsx("div", {}), _jsx(VerticalDataValue, { title: "vat number", value: "-" }), _jsx(VerticalDataValue, { title: "Tax status", value: "-" }), _jsx(VerticalDataValue, { title: "Days until due", value: user.invoice_due_in }), _jsx(VerticalDataValue, { title: "Vat Check", value: "-" }), _jsx(VerticalDataValue, { title: "Custom reference on invoice", value: "-" }), _jsx(VerticalDataValue, { title: "Do not send invoice reminders until", value: "-" })] }), _jsxs("div", { children: [_jsx("h3", { children: "Invoice items to be added to next invoice" }), _jsx("div", { children: "Invoice items" })] })] }), _jsx("div", { className: "box-outerlayout", children: _jsx("h2", { children: "Vouchers" }) }), _jsx("div", { className: "box-outerlayout", children: _jsx("h2", { children: "Subscriptions" }) }), _jsx("div", { className: "box-outerlayout", children: _jsx("h2", { children: "Invoices" }) })] }));
}
