var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { T } from "@tolgee/react";
import { debounce } from "lodash";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultDataPoints from "components/DSRSettings/CCPA/DefaultDataPoints";
import PrivacyPolicyDsr from "components/DSRSettings/CCPA/PrivacyPolicyDsr";
import CategoriesCollected from "components/DSRSettings/CCPA/CategoriesCollected";
import SharingData from "components/DSRSettings/CCPA/SharingData";
import DisclosingData from "components/DSRSettings/CCPA/DisclosingData";
import EnableDocumentUpload from "components/DSRSettings/CCPA/EnableDocumentUpload";
import BetaProgram from "components/DSRSettings/CCPA/BetaProgram";
import { api } from "services";
import { notify } from "store/app";
import tolgee from "services/translation";
import { categoriesCollectedData, sharedSoldData, disclosedData, defaultDataPoints, ccpaPrivacyPolicySnippet, optOutLink, } from "const/ccpa";
import { DefaultPointType } from "types/ccpaSettings";
import { BaseModal } from "components/DataDisplay/Modal";
import { BaseButton } from "components/Button";
import { BaseTextField } from "components/Input";
export default function CCPAView() {
    var _this = this;
    var dispatch = useDispatch();
    var isFirstRender = useRef(true);
    var businessId = useParams().businessId;
    var _a = useState({
        isModalDisplayed: false,
        item: null,
    }), alreadySelectedModal = _a[0], setAlreadySelectedModal = _a[1];
    var _b = useState({
        selectedItems: [],
        options: [],
    }), defaultPoints = _b[0], setDefaultPoints = _b[1];
    var _c = useState(false), privacyPolicyDsrEnabled = _c[0], setPrivacyPolicyDsrEnabled = _c[1];
    var _d = useState({
        isEnabled: true,
        selectedItems: [],
        options: [],
    }), categoriesCollected = _d[0], setCategoriesCollected = _d[1];
    var _e = useState({
        isEnabled: false,
        selectedItems: [],
        options: [],
    }), sharingData = _e[0], setSharingData = _e[1];
    var _f = useState({
        isEnabled: false,
        selectedItems: [],
        options: [],
    }), disclosingData = _f[0], setDisclosingData = _f[1];
    var _g = useState(false), requiredDocumentsUpload = _g[0], setRequiredDocumentsUpload = _g[1];
    useEffect(function () {
        fetchData();
    }, [businessId]);
    useEffect(function () {
        // Update sharingData & disclosingData options
        var newShareDataOptions = __spreadArray([], sharingData.options, true);
        var newDisclosingDataOptions = __spreadArray([], disclosingData.options, true);
        var _loop_1 = function (i) {
            if (categoriesCollected.selectedItems.find(function (item) { return item.key === newShareDataOptions[i].dataToSell.key; })) {
                newShareDataOptions[i].isAvailable = true;
            }
            else {
                newShareDataOptions[i].isAvailable = false;
            }
        };
        for (var i = 0; i < newShareDataOptions.length; i += 1) {
            _loop_1(i);
        }
        var _loop_2 = function (i) {
            if (categoriesCollected.selectedItems.find(function (item) { return item.key === newDisclosingDataOptions[i].dataToSell.key; })) {
                newDisclosingDataOptions[i].isAvailable = true;
            }
            else {
                newDisclosingDataOptions[i].isAvailable = false;
            }
        };
        for (var i = 0; i < newDisclosingDataOptions.length; i += 1) {
            _loop_2(i);
        }
        if (newShareDataOptions.length > 0) {
            setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newShareDataOptions })); });
        }
        if (newDisclosingDataOptions.length > 0) {
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newDisclosingDataOptions })); });
        }
    }, [categoriesCollected.selectedItems]);
    useEffect(function () {
        if (isFirstRender.current) {
            // Skip the first render
            isFirstRender.current = false;
            return;
        }
        var payloadData = {
            required_inputs: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "required_inputs" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            required_inputs_strict: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "required_inputs_strict" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            additional_inputs: defaultPoints.selectedItems
                .filter(function (dataItem) { return dataItem.type === "additional_inputs" && dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            categories_collected: categoriesCollected.selectedItems
                .filter(function (dataItem) { return dataItem.value; })
                .map(function (mapItem) { return mapItem.key; }) || [],
            categories_shared: prepareCategories(sharingData),
            categories_disclosed: prepareCategories(disclosingData),
            sell_share: sharingData.isEnabled,
            disclose: disclosingData.isEnabled,
            collect: categoriesCollected.isEnabled,
            document_upload: requiredDocumentsUpload,
            send_privacy_policy: privacyPolicyDsrEnabled,
        };
        putDataToBackend(payloadData);
    }, [
        defaultPoints.selectedItems,
        categoriesCollected.isEnabled,
        categoriesCollected.selectedItems,
        sharingData.isEnabled,
        sharingData.selectedItems,
        disclosingData.isEnabled,
        disclosingData.selectedItems,
        requiredDocumentsUpload,
        privacyPolicyDsrEnabled,
    ]);
    var fetchData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.ccpa.getCCPASettings(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({ message: tolgee.t("generic.smth_went_wrong"), type: "ERROR" }));
                        return [2 /*return*/];
                    }
                    handleBackendCcpaSettings(res);
                    return [2 /*return*/];
            }
        });
    }); };
    var putDataToBackend = debounce(function (data) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.ccpa.updateCCPASettings(businessId, data)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); }, 500);
    var prepareCategories = function (data) {
        var obj = {};
        for (var i = 0; i < (data === null || data === void 0 ? void 0 : data.selectedItems.length); i += 1) {
            if (data.selectedItems[i].dataToSell.key && data.selectedItems[i].sellTo.length > 0) {
                obj[data.selectedItems[i].dataToSell.key] = data.selectedItems[i].sellTo.map(function (item) { return item.key; });
            }
        }
        return obj;
    };
    var handleBackendCcpaSettings = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var formattedDefaultPointOptions, formattedCategoriesCollectedItems, formattedSharingDataOptions, formattedDisclosingDataOptions;
        return __generator(this, function (_a) {
            formattedDefaultPointOptions = defaultDataPoints.map(function (item) { return ({
                key: item,
                value: tolgee.t("dsr.settings.ccpa.default_data.".concat(item)),
                selected: false,
            }); });
            setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedDefaultPointOptions })); });
            handleDefaultPointsInitialValue(data, formattedDefaultPointOptions);
            // Privacy Policy
            setPrivacyPolicyDsrEnabled(data.send_privacy_policy || false);
            formattedCategoriesCollectedItems = categoriesCollectedData.map(function (item) { return ({
                key: item,
                value: tolgee.t({ key: "".concat(item) }),
                selected: false,
            }); });
            setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedCategoriesCollectedItems })); });
            handleCategoriesCollectedInitialValue(data, formattedCategoriesCollectedItems);
            formattedSharingDataOptions = sharedSoldData.map(function (item) { return ({
                dataToSell: {
                    key: item.dataToSell,
                    value: tolgee.t({ key: "".concat(item.dataToSell) }),
                },
                sellOptions: item.sellOptions.map(function (sellOptionItem) { return ({
                    key: sellOptionItem,
                    value: tolgee.t({ key: "".concat(sellOptionItem) }),
                }); }),
                selected: false,
                isAvailable: false,
            }); });
            setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedSharingDataOptions })); });
            handleSharingDataInitialValue(data, formattedSharingDataOptions);
            formattedDisclosingDataOptions = disclosedData.map(function (item) { return ({
                dataToSell: {
                    key: item.dataToSell,
                    value: tolgee.t({ key: "".concat(item.dataToSell) }),
                },
                sellOptions: item.sellOptions.map(function (sellOptionItem) { return ({
                    key: sellOptionItem,
                    value: tolgee.t({ key: "".concat(sellOptionItem) }),
                }); }),
                selected: false,
                isAvailable: false,
            }); });
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: formattedDisclosingDataOptions })); });
            handleDisclosingDataInitialValue(data, formattedDisclosingDataOptions);
            return [2 /*return*/];
        });
    }); };
    var handleDefaultPointsInitialValue = function (data, options) {
        var localOptions = options;
        var requiredInputs = data.required_inputs;
        var requiredInputsStrict = data.required_inputs_strict;
        var additionalInputs = data.additional_inputs;
        var items = [];
        var _loop_3 = function (i) {
            var item = {
                id: i,
                key: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).key,
                value: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).value,
                type: DefaultPointType.required_inputs,
            };
            localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputs[i]; }).selected = true;
            items.push(item);
        };
        for (var i = 0; i < requiredInputs.length; i += 1) {
            _loop_3(i);
        }
        if (requiredInputsStrict) {
            var _loop_4 = function (i) {
                var strictItem = {
                    id: items.length + 1,
                    key: localOptions.find(function (formattedItem) { return formattedItem.key === requiredInputsStrict[i]; })
                        .key,
                    value: localOptions.find(function (item) { return item.key === requiredInputsStrict[i]; }).value,
                    type: DefaultPointType.required_inputs_strict,
                };
                localOptions.find(function (item) { return item.key === requiredInputsStrict[0]; }).selected = true;
                items.push(strictItem);
            };
            for (var i = 0; i < requiredInputsStrict.length; i += 1) {
                _loop_4(i);
            }
        }
        if (additionalInputs) {
            var _loop_5 = function (i) {
                var additionalItem = {
                    id: items.length + 1,
                    key: localOptions.find(function (formattedItem) { return formattedItem.key === additionalInputs[i]; }).key,
                    value: localOptions.find(function (item) { return item.key === additionalInputs[i]; }).value,
                    type: DefaultPointType.additional_inputs,
                };
                localOptions.find(function (item) { return item.key === additionalInputs[i]; }).selected = true;
                items.push(additionalItem);
            };
            for (var i = 0; i < additionalInputs.length; i += 1) {
                _loop_5(i);
            }
        }
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: items, options: localOptions })); });
    };
    var handleCategoriesCollectedInitialValue = function (data, options) {
        var items = [];
        var localOptions = options;
        if (data.categories_collected) {
            var _loop_6 = function (i) {
                var collectedItem = {
                    id: items.length + 1,
                    key: options.find(function (formattedItem) { return formattedItem.key === data.categories_collected[i]; })
                        .key,
                    value: options.find(function (item) { return item.key === data.categories_collected[i]; }).value,
                };
                localOptions.find(function (item) { return item.key === data.categories_collected[i]; }).selected = true;
                items.push(collectedItem);
            };
            for (var i = 0; i < data.categories_collected.length; i += 1) {
                _loop_6(i);
            }
        }
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.collect, selectedItems: items, options: localOptions })); });
    };
    var handleSharingDataInitialValue = function (data, options) {
        var shareData = data.categories_shared;
        var localOptions = __spreadArray([], options, true);
        var shareDataItems = [];
        Object.keys(shareData).forEach(function (key) {
            var formattedItem = options.find(function (item) { return item.dataToSell.key === key; });
            var selectedItem = {
                dataToSell: formattedItem.dataToSell,
                sellOptions: formattedItem.sellOptions,
                // @ts-expect-error ---
                sellTo: shareData[key].map(function (val) {
                    return formattedItem.sellOptions.find(function (option) { return option.key === val; });
                }),
            };
            shareDataItems.push(selectedItem);
            localOptions.find(function (item) { return item.dataToSell.key === selectedItem.dataToSell.key; }).selected =
                true;
        });
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.sell_share, selectedItems: shareDataItems, options: localOptions })); });
    };
    var handleDisclosingDataInitialValue = function (data, options) {
        var discloseData = data.categories_disclosed;
        var discloseDataItems = [];
        Object.keys(discloseData).forEach(function (key) {
            var formattedItem = options.find(function (item) { return item.dataToSell.key === key; });
            var selectedItem = {
                dataToSell: formattedItem.dataToSell,
                sellOptions: formattedItem.sellOptions,
                // @ts-expect-error ---
                sellTo: discloseData[key].map(function (val) {
                    return formattedItem.sellOptions.find(function (option) { return option.key === val; });
                }),
            };
            discloseDataItems.push(selectedItem);
            var newData = __spreadArray([], options, true);
            newData.find(function (item) { return item.dataToSell.key === selectedItem.dataToSell.key; }).selected = true;
            setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        });
        if (discloseDataItems.length === 0) {
            var item = {
                dataToSell: {
                    key: "",
                    value: "",
                },
                sellOptions: [],
                sellTo: [],
            };
            discloseDataItems.push(item);
        }
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: data.disclose, selectedItems: discloseDataItems })); });
    };
    var handleDefaultPointSelect = function (updatedItem, updatedValue) {
        var updatedDataItems = defaultPoints.selectedItems.map(function (item) {
            return item.id === updatedItem.id
                ? __assign(__assign({}, item), { key: defaultPoints.options.find(function (dataItem) { return dataItem.value === updatedValue; }).key, value: updatedValue }) : item;
        });
        var updatedOptions = defaultPoints.options.map(function (item) {
            // Update selected one
            if (item.value === updatedValue) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            // Update previously selected one
            if (item.value === updatedItem.value) {
                return __assign(__assign({}, item), { selected: false });
            }
            // Rest of items just return
            return item;
        });
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedDataItems, options: updatedOptions })); });
    };
    var handleAdditionalPointDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], defaultPoints.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.id === deletionItem.id; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = defaultPoints.options.map(function (item) {
            if (item.key === deletionItem.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addAdditionalEmptyPoint = function () {
        var item = {
            id: defaultPoints.selectedItems.length + 1,
            key: "",
            value: "",
            type: DefaultPointType.additional_inputs,
        };
        var newDataItems = __spreadArray([], defaultPoints.selectedItems, true);
        newDataItems.push(item);
        setDefaultPoints(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: newDataItems })); });
    };
    var handleCategoriesCollectedSelect = function (updatedItem, updatedValue) {
        var updatedDataItems = categoriesCollected.selectedItems.map(function (item) {
            return item.id === updatedItem.id
                ? __assign(__assign({}, item), { key: categoriesCollected.options.find(function (dataItem) { return dataItem.value === updatedValue; })
                        .key, value: updatedValue }) : item;
        });
        var updatedOptions = categoriesCollected.options.map(function (item) {
            // Update selected one
            if (item.value === updatedValue) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            // Update previously selected one
            if (item.value === updatedItem.value) {
                return __assign(__assign({}, item), { selected: false });
            }
            // Rest of items just return
            return item;
        });
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedDataItems, options: updatedOptions })); });
    };
    var handleCategoriesCollectedDeletion = function (deletionItem) {
        if (sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }) ||
            disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: true, item: deletionItem })); });
            return;
        }
        deleteCategoriesCollectedItem(deletionItem);
    };
    var deleteCategoriesCollectedItem = function (deletionItem) {
        var updatedItems = __spreadArray([], categoriesCollected.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.key === deletionItem.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = categoriesCollected.options.map(function (item) {
            if (item.key === deletionItem.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var deleteAlreadySelectedItem = function (deletionItem) {
        deleteCategoriesCollectedItem(deletionItem);
        if (sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            handleSharingDataDeletion(sharingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }));
        }
        if (disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; })) {
            handleDisclosingDataDeletion(disclosingData.selectedItems.find(function (item) { return item.dataToSell.key === deletionItem.key; }));
        }
    };
    var addEmptyCategoriesCollectedData = function () {
        var item = {
            id: categoriesCollected.selectedItems.length + 1,
            key: "",
            value: "",
        };
        var newDataItems = __spreadArray([], categoriesCollected.selectedItems, true);
        newDataItems.push(item);
        setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: newDataItems })); });
    };
    var handleSharingDataCategory1Select = function (selectedItem, index, value) {
        var localItem = selectedItem;
        var newData = __spreadArray([], sharingData.options, true);
        if (localItem.dataToSell.value !== "") {
            newData.find(function (item) { return item.dataToSell.value === localItem.dataToSell.value; }).selected = false;
        }
        newData.find(function (item) { return item.dataToSell.value === value; }).selected = true;
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        if (localItem.dataToSell.value !== value) {
            localItem = {
                dataToSell: {
                    key: sharingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                },
                sellTo: [],
                sellOptions: sharingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions,
            };
        }
        else {
            localItem = __assign(__assign({}, selectedItem), { dataToSell: {
                    key: sharingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell.key,
                    value: value,
                }, sellOptions: sharingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions });
        }
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], sharingData.selectedItems.slice(0, index), true), [
            localItem
        ], false), sharingData.selectedItems.slice(index + 1), true);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleSharingDataCategory2Select = function (selectedItem, index, value) {
        var item = __assign(__assign({}, selectedItem), { sellTo: value.map(function (val) {
                return selectedItem.sellOptions.find(function (option) { return option.value === val; });
            }) });
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], sharingData.selectedItems.slice(0, index), true), [
            item
        ], false), sharingData.selectedItems.slice(index + 1), true);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleSharingDataDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], sharingData.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.dataToSell.key === deletionItem.dataToSell.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = sharingData.options.map(function (item) {
            if (item.dataToSell.key === deletionItem.dataToSell.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addEmptySharingDataCategory = function () {
        var item = {
            dataToSell: {
                key: "",
                value: "",
            },
            sellOptions: [],
            sellTo: [],
        };
        var updatedItems = __spreadArray(__spreadArray([], sharingData.selectedItems, true), [item], false);
        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataCategory1Select = function (selectedItem, index, value) {
        var localItem = selectedItem;
        var newData = __spreadArray([], disclosingData.options, true);
        if (localItem.dataToSell.value !== "") {
            newData.find(function (item) { return item.dataToSell.value === localItem.dataToSell.value; }).selected = false;
        }
        newData.find(function (item) { return item.dataToSell.value === value; }).selected = true;
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { options: newData })); });
        if (localItem.dataToSell.value !== value) {
            localItem = {
                dataToSell: {
                    key: disclosingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell
                        .key,
                    value: value,
                },
                sellTo: [],
                sellOptions: disclosingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions,
            };
        }
        else {
            localItem = __assign(__assign({}, selectedItem), { dataToSell: {
                    key: disclosingData.options.find(function (item) { return item.dataToSell.value === value; }).dataToSell
                        .key,
                    value: value,
                }, sellOptions: disclosingData.options.filter(function (item) { return item.dataToSell.value === value; })[0]
                    .sellOptions });
        }
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], disclosingData.selectedItems.slice(0, index), true), [
            localItem
        ], false), disclosingData.selectedItems.slice(index + 1), true);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataCategory2Select = function (selectedItem, index, value) {
        var item = __assign(__assign({}, selectedItem), { sellTo: value.map(function (val) {
                return selectedItem.sellOptions.find(function (option) { return option.value === val; });
            }) });
        var updatedItems = __spreadArray(__spreadArray(__spreadArray([], disclosingData.selectedItems.slice(0, index), true), [
            item
        ], false), disclosingData.selectedItems.slice(index + 1), true);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var handleDisclosingDataDeletion = function (deletionItem) {
        var updatedItems = __spreadArray([], disclosingData.selectedItems, true);
        var itemIndex = updatedItems.findIndex(function (item) { return item.dataToSell.key === deletionItem.dataToSell.key; });
        updatedItems.splice(itemIndex, 1);
        var updatedOptions = disclosingData.options.map(function (item) {
            if (item.dataToSell.key === deletionItem.dataToSell.key) {
                return __assign(__assign({}, item), { selected: !item.selected });
            }
            return item;
        });
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems, options: updatedOptions })); });
    };
    var addEmptyDisclosingDataCategory = function () {
        var item = {
            dataToSell: {
                key: "",
                value: "",
            },
            sellOptions: [],
            sellTo: [],
        };
        var updatedItems = __spreadArray(__spreadArray([], disclosingData.selectedItems, true), [item], false);
        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { selectedItems: updatedItems })); });
    };
    var htmlSnippet = ccpaPrivacyPolicySnippet(optOutLink(businessId));
    return (_jsx(_Fragment, { children: _jsx("div", { className: "flex flex-col", children: _jsxs("div", { children: [_jsxs("div", { className: "mt-4 mb-4 box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { keyName: "dsr.settings.ccpa.title" }) }), _jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.description" }) }), _jsx("div", { className: "mb-4", children: _jsx(DefaultDataPoints, { defaultPointsData: defaultPoints.selectedItems, defaultPointsOptions: defaultPoints.options, handleSelection: handleDefaultPointSelect, handleDeletion: handleAdditionalPointDeletion, addAdditionalPoint: addAdditionalEmptyPoint }) }), _jsx("div", { className: "mb-4", children: _jsx(PrivacyPolicyDsr, { isEnabled: privacyPolicyDsrEnabled, handleEnabled: function (value) {
                                        setPrivacyPolicyDsrEnabled(value);
                                    } }) }), !privacyPolicyDsrEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx(CategoriesCollected, { isEnabled: categoriesCollected.isEnabled, categoriesCollectedData: categoriesCollected.selectedItems, categoriesCollectedOptions: categoriesCollected.options, handleEnabled: function (value) {
                                                setCategoriesCollected(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value })); });
                                                // If disabled then clear and disable sharingData & disclosingData
                                                if (!value) {
                                                    setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: false, selectedItems: [] })); });
                                                    setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: false, selectedItems: [] })); });
                                                }
                                            }, handleSelection: handleCategoriesCollectedSelect, handleDeletion: handleCategoriesCollectedDeletion, addEmptyCategory: addEmptyCategoriesCollectedData }) }), _jsx(BaseModal, { modalState: [
                                            alreadySelectedModal.isModalDisplayed,
                                            function (isDisplayed) {
                                                return setAlreadySelectedModal(function (prev) { return (__assign(__assign({}, prev), { isModalDisplayed: isDisplayed })); });
                                            },
                                        ], children: _jsxs("div", { className: "w-[40rem] overflow-auto", "data-testid": "categoriesCollected_deleteItemFromOtherPlaces", children: [_jsx("div", { className: "mb-4", children: _jsx(T, { keyName: "dsr.settings.ccpa.categories_collected.item_already_selected" }) }), _jsxs("div", { className: "flex flex-row justify-end gap-6", children: [_jsx(BaseButton, { testid: "categoriesCollected_deleteItemFromOtherPlaces_confirm", color: "error", variant: "outlined", onClick: function () {
                                                                setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: false })); });
                                                                deleteAlreadySelectedItem(alreadySelectedModal.item);
                                                            }, children: _jsx(T, { keyName: "generic.yes" }) }), _jsx(BaseButton, { color: "primary", onClick: function () {
                                                                return setAlreadySelectedModal(function (prevState) { return (__assign(__assign({}, prevState), { isModalDisplayed: false, item: null })); });
                                                            }, children: _jsx(T, { keyName: "generic.no" }) })] })] }) }), categoriesCollected.isEnabled ? (_jsxs("div", { children: [_jsx("div", { className: "mb-4", children: _jsx(SharingData, { isEnabled: sharingData.isEnabled, shareData: sharingData.selectedItems, shareDataOptions: sharingData.options, handleEnabled: function (value) {
                                                        var initialItem = {
                                                            dataToSell: {
                                                                key: "",
                                                                value: "",
                                                            },
                                                            sellOptions: [],
                                                            sellTo: [],
                                                        };
                                                        setSharingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value, selectedItems: value ? [initialItem] : [] })); });
                                                    }, handleCategory1Select: handleSharingDataCategory1Select, handleCategory2Select: handleSharingDataCategory2Select, handleDeletion: handleSharingDataDeletion, addEmptyCategory: addEmptySharingDataCategory }) }), _jsx("div", { className: "mb-4", children: _jsx(DisclosingData, { isEnabled: disclosingData.isEnabled, discloseData: disclosingData.selectedItems, discloseDataOptions: disclosingData.options, handleEnabled: function (value) {
                                                        var initialItem = {
                                                            dataToSell: {
                                                                key: "",
                                                                value: "",
                                                            },
                                                            sellOptions: [],
                                                            sellTo: [],
                                                        };
                                                        setDisclosingData(function (prevState) { return (__assign(__assign({}, prevState), { isEnabled: value, selectedItems: value ? [initialItem] : [] })); });
                                                    }, handleCategory1Select: handleDisclosingDataCategory1Select, handleCategory2Select: handleDisclosingDataCategory2Select, handleDeletion: handleDisclosingDataDeletion, addEmptyCategory: addEmptyDisclosingDataCategory }) })] })) : ("")] })) : (""), _jsx("div", { className: "mb-4", children: _jsx(EnableDocumentUpload, { isEnabled: requiredDocumentsUpload, handleEnabled: function (value) { return setRequiredDocumentsUpload(value); } }) })] }), _jsxs("div", { className: "box-outerlayout", children: [_jsx("h2", { children: _jsx(T, { keyName: "ccpa.policy_snippet.title" }) }), _jsx(T, { keyName: "ccpa.policy_snippet.description" }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full", children: [_jsx("div", { className: "mb-6", children: _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, disabled: true, value: htmlSnippet }) }), _jsx("div", { className: "flex justify-end mt-4", children: _jsxs(Button, { onClick: function () { var _a; return (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(htmlSnippet); }, children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: "copy", color: "gray", size: "lg" }), _jsx(T, { keyName: "ccpa.copy" })] }) })] })] }), _jsx(BetaProgram, {})] }) }) }));
}
