import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button } from "@mui/material";
import { T } from "@tolgee/react";
import { useMemo, useState } from "react";
import { Link } from "react-router";
import { useAppSelector } from "store";
export default function ToDoList(_a) {
    var userToDos = _a.userToDos, businessTodos = _a.businessTodos;
    var _b = useState(false), showAll = _b[0], setShowAll = _b[1];
    var todosLeft = useMemo(function () {
        var count = userToDos.filter(function (i) { return !i.done; }).length;
        Object.values(businessTodos).forEach(function (businessTodoList) {
            count += businessTodoList.filter(function (j) { return !(j === null || j === void 0 ? void 0 : j.done); }).length;
        });
        return count;
    }, [userToDos, businessTodos]);
    var managed_businesses = useAppSelector(function (state) { return state.user; }).managed_businesses;
    var getCompanyName = function (businessId) {
        var _a;
        return ((_a = managed_businesses.find(function (b) { return b.public_id === businessId; })) === null || _a === void 0 ? void 0 : _a.company_name) || businessId;
    };
    return (_jsxs("div", { className: "box-outerlayout", "data-testid": "todos-section", children: [_jsxs("div", { className: "p-0 ".concat(!showAll && "max-h-[36rem]", " overflow-y-auto"), children: [_jsx("h2", { className: "pb-4 text-2xl border-0 border-b border-gray-200 border-solid md:px-4 text-brand-900", children: _jsx(T, { keyName: "dashboard.complete_onboarding" }) }), userToDos.length > 0 && (_jsxs("div", { className: "flex flex-col pt-0 space-y-1 md:p-4", children: [_jsx("h4", { className: "mt-0 mb-4", children: _jsx(T, { keyName: "dashboard.account_tasks" }) }), userToDos.map(function (todo) { return (_jsx(Link, { to: todo.action_url, style: todo.done ? { pointerEvents: "none" } : null, children: _jsxs("div", { className: "flex flex-row items-center justify-between px-4 py-2 rounded-lg", "data-testid": "user-todo", children: [_jsx("p", { className: "text-md leading-6 text-gray-900", children: todo.title }), _jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx("p", { className: "text-sm leading-6 text-gray-900", children: todo.action_title }), _jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "lg" })] })] }) }, todo.id)); })] })), Object.entries(businessTodos).map(function (_a) {
                        var businessId = _a[0], businessTodoList = _a[1];
                        return (businessTodoList === null || businessTodoList === void 0 ? void 0 : businessTodoList.length) > 0 && (_jsxs("div", { className: "flex flex-col pt-0 space-y-1 md:p-4", "data-testid": "business-todo", children: [_jsxs("h4", { className: "mt-0 mb-4", children: ["Complete ", getCompanyName(businessId), "'s Onboarding"] }), businessTodoList
                                    .filter(function (t) { return !t.done; })
                                    .map(function (todo) { return (_jsx(Link, { to: todo.action_url, style: todo.done ? { pointerEvents: "none" } : null, children: todo.warning ? (_jsx(Alert, { severity: "warning", sx: {
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            borderRadius: "8px",
                                            padding: "8px 16px",
                                            "& .MuiAlert-message": {
                                                flex: 1,
                                                padding: 0,
                                            },
                                        }, children: _jsxs("div", { className: "flex flex-row items-center justify-between", children: [_jsx("p", { className: "text-md leading-6 text-gray-900", children: todo.title }), _jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx("p", { className: "text-sm leading-6 text-gray-900", children: todo.action_title }), _jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "lg" })] })] }) })) : (_jsxs("div", { className: "flex flex-row items-center justify-between px-4 py-2 rounded-lg", children: [_jsx("p", { className: "text-md leading-6 text-gray-900", children: todo.title }), _jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx("p", { className: "text-sm leading-6 text-gray-900", children: todo.action_title }), _jsx(FontAwesomeIcon, { icon: "chevron-right", className: "text-gray-400", size: "lg" })] })] })) }, todo.id)); })] }, businessId));
                    })] }), todosLeft > 7 && (_jsx(Button, { onClick: function () { return setShowAll(!showAll); }, children: showAll ? _jsx(T, { keyName: "generic.show_less" }) : _jsx(T, { keyName: "generic.show_all" }) }))] }));
}
