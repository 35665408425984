var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { T } from "@tolgee/react";
import { faker } from "faker";
import { api } from "services";
import { BaseButton, PrighterFeedback, ProgressBar } from "components";
import { jurisdictionDict } from "const";
import CustomizedStepper from "components/Navigation/Stepper";
import RightsForm from "pages/Public/Dsr/Fadp/Step1/RightsForm";
import AdditionalInfoForm from "pages/Public/Dsr/Fadp/Step3/AdditionalInfoForm";
import RequestFormContainer from "components/PageSpecific/Dsr/RequestFormContainer";
import IdentificationForm from "pages/Public/Dsr/Fadp/Step2/IdentificationForm";
import { useAppDispatch, useAppSelector } from "store";
import { notify, setError } from "store/app";
import tolgee from "services/translation";
import { DEV_ENV } from "const/env";
import { fadpFormInitialValues } from "pages/Public/Dsr/Fadp/fadpRequestObject";
import { useDebounce } from "helpers/hooks";
export default function FadpRequestForm(_a) {
    var _this = this;
    var step = _a.step;
    var publicId = useParams().publicId;
    var dispatch = useAppDispatch();
    var countries = useAppSelector(function (state) { return state.app; }).countries;
    var navigate = useNavigate();
    var _b = useState({}), businessState = _b[0], setBusinessState = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), displaySuccessMessage = _d[0], setDisplaySuccessMessage = _d[1];
    var _e = useState(false), isFormSet = _e[0], setIsFormSet = _e[1];
    var methods = useForm({ values: fadpFormInitialValues });
    var watch = methods.watch, trigger = methods.trigger, getValues = methods.getValues, setValue = methods.setValue, reset = methods.reset;
    var request_privacy_related = watch("request_privacy_related");
    var form = watch();
    var debouncedValue = useDebounce(JSON.stringify(form), 2000);
    var hasRenderedOnce = useRef(false);
    useEffect(function () {
        if (isFormSet && hasRenderedOnce.current) {
            localStorage.setItem("fadp_form", debouncedValue);
        }
        else if (isFormSet && !hasRenderedOnce.current) {
            hasRenderedOnce.current = true;
        }
    }, [debouncedValue, isFormSet]);
    useEffect(function () {
        function getBusiness() {
            return __awaiter(this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.dsr.getDsrBusiness(publicId)];
                        case 1:
                            res = _a.sent();
                            if (!res || !res.jurisdictions.includes("dsr_fadp")) {
                                dispatch(setError("404"));
                                return [2 /*return*/];
                            }
                            if (countries.length > 0 && DEV_ENV && !getValues("actors.0.address.country_iso")) {
                                setValue("actors.0.address.country_iso", countries[faker.number.int({
                                    min: 0,
                                    max: countries.length - 1,
                                })].iso_3166_1_alpha_2);
                            }
                            setBusinessState(res);
                            document.title = "".concat(res.company_name, ": FADP");
                            setLoading(false);
                            return [2 /*return*/];
                    }
                });
            });
        }
        reset(JSON.parse(localStorage.getItem("fadp_form") || "{}"));
        setIsFormSet(true);
        getBusiness();
    }, [countries, dispatch, getValues, publicId, reset, setValue]);
    var checkStep = function () {
        switch (step) {
            case "identification":
                return trigger(["actors", "files", "identifiable_info"]);
            case "send":
                return trigger();
            default:
                return true;
        }
    };
    var handleContinue = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkStep()];
                case 1:
                    if (_a.sent()) {
                        switch (step) {
                            case "type":
                                navigate("/dsr/".concat(publicId, "/fadp/identification"));
                                break;
                            case "identification":
                                navigate("/dsr/".concat(publicId, "/fadp/send"));
                                break;
                            case "send":
                            default:
                                break;
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleBack = function () {
        switch (step) {
            case "identification":
                navigate("/dsr/".concat(publicId, "/fadp"));
                break;
            case "send":
                navigate("/dsr/".concat(publicId, "/fadp/identification"));
                break;
            default:
                break;
        }
    };
    var handleSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var tempValues, valuesToSubmit, success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    tempValues = getValues();
                    valuesToSubmit = __assign(__assign({}, tempValues), { dsrs: tempValues.dsrs.filter(function (dsr) { return dsr.checked; }), type: "DSR_FADP" });
                    return [4 /*yield*/, api.fadp.createDsrFadpCase(publicId, valuesToSubmit)];
                case 1:
                    success = _a.sent();
                    if (success) {
                        setDisplaySuccessMessage(success);
                        localStorage.removeItem("fadp_form");
                    }
                    else {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var shouldButtonDisabled = function () {
        if (step === "send" && !request_privacy_related) {
            return true;
        }
        if (step === "type" && form.dsrs.filter(function (dsr) { return dsr.checked; }).length === 0) {
            return true;
        }
        return false;
    };
    return (_jsxs(_Fragment, { children: [loading && _jsx(ProgressBar, {}), !loading && displaySuccessMessage && (_jsx("div", { className: "flex justify-center items-center w-full h-screen bg-gray-50", children: _jsx(PrighterFeedback, { children: _jsxs("div", { className: "px-16 pb-6 flex flex-col items-center text-center", children: [_jsx("span", { className: "text-xl text-gray-700", children: _jsx(T, { keyName: "dsr.thank_you_for_your_request" }) }), _jsxs("span", { className: "text-gray-600 text-sm mt-1 mb-6", children: [_jsx(T, { keyName: "dsr.please_verify_email_address" }), _jsx("br", {}), _jsx(T, { keyName: "dsr.ds_request_success_message" })] })] }) }) })), !loading && !displaySuccessMessage && businessState.public_id && (_jsxs(RequestFormContainer, { headerState: businessState, children: [_jsxs("div", { className: "flex justify-between", children: [_jsx("h1", { children: _jsx(T, { keyName: "dsr.make_a_privacy_related_request_to", params: { company_name: businessState.company_name } }) }), _jsx("img", { src: jurisdictionDict.dsr_fadp.jurisdiction_lock_icon_url, alt: "switzerland-logo", width: "64" })] }), _jsx(CustomizedStepper, { currentStep: step }), _jsxs(FormProvider, __assign({}, methods, { children: [step === "type" && _jsx(RightsForm, {}), step === "identification" && _jsx(IdentificationForm, {}), step === "send" && _jsx(AdditionalInfoForm, { data: getValues() })] })), _jsxs("div", { className: "flex ".concat(step === "type" ? "justify-end" : "justify-between", " mt-4"), children: [step !== "type" && (_jsx(BaseButton, { onClick: handleBack, children: _jsx(T, { keyName: "generic.back" }) })), _jsx(BaseButton, { disabled: shouldButtonDisabled(), onClick: step === "send" ? handleSubmit : handleContinue, children: step === "send" ? _jsx(T, { keyName: "generic.submit" }) : _jsx(T, { keyName: "generic.continue" }) })] })] }))] }));
}
