var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@tolgee/react";
import { TextField } from "@mui/material";
import { Link, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { api } from "services";
import { notify } from "store/app";
import tolgee from "services/translation";
export default function Info() {
    var _this = this;
    var businessId = useParams().businessId;
    var _a = useState(), configData = _a[0], setConfigData = _a[1];
    var dispatch = useDispatch();
    var fetchConfigData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.clp.getBusinessConfig(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    setConfigData(res);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId, dispatch]);
    useEffect(function () {
        fetchConfigData();
    }, [fetchConfigData]);
    return (_jsxs("div", { className: "box-outerlayout pb-6 pt-10", "data-testid": "info-section", children: [_jsxs("div", { className: "flex flex-col md:flex-row md:space-x-6 items-center mb-4 md:px-6", children: [_jsx("div", { className: "mr-4 mb-6 md:mb-0", children: _jsx(FontAwesomeIcon, { size: "xl", icon: "exclamation-triangle", color: "danger" }) }), _jsxs("div", { children: [_jsx("div", { className: "font-bold mt-6 mb-2", children: _jsx(T, { keyName: "gdpr.eurep_the_fact" }) }), _jsxs("div", { children: [_jsx(T, { keyName: "gdpr.eurep_to_comply" }), _jsx("a", { href: "#policysnippets", children: _jsx(T, { keyName: "gdpr.eurep_these_snippets" }) }), _jsxs("div", { className: "mt-4", children: [_jsx(T, { keyName: "gdpr.eurep_contact" }), _jsxs(Link, { to: "/customer-support", children: [" ", _jsx(T, { keyName: "gdpr.eurep_contact_us" })] })] })] })] })] }), _jsxs("div", { className: "font-bold mt-6", children: [_jsx(T, { keyName: "gdpr.please_verify" }), ":"] }), _jsxs("div", { className: "mt-6 border rounded-lg py-6 px-4 bg-gray-50", children: [_jsx(TextField, { label: _jsx(T, { keyName: "gdpr.eurep_url_privacy" }), InputLabelProps: { shrink: true }, className: "w-full bg-white", value: (configData === null || configData === void 0 ? void 0 : configData.privacy_policy_ext_url) || "", disabled: true }), _jsxs("div", { className: "mt-4", children: [_jsx(T, { keyName: "gdpr.modify_url" }), _jsxs(Link, { to: "/business/".concat(businessId, "/profile"), children: [" ", _jsx(T, { keyName: "gdpr.click_here" })] }), "."] })] })] }));
}
