var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useReducer, useRef } from "react";
import { useParams } from "react-router";
import NotFound from "pages/General/Error/NotFound";
import { fetchCaseData } from "store/thunks";
import AuthorityView from "pages/Client/Case/Authority/View";
import DSRView from "pages/Client/Case/DSR/ClientView/View";
import providerState from "pages/Client/Case/CaseContext/initialState";
import providerReducer from "pages/Client/Case/CaseContext/reducer";
import { DispatchContext, StateContext } from "pages/Client/Case/CaseContext";
import { api } from "services";
export default function CaseView() {
    var _a;
    var caseUuid = useParams().caseUuid;
    var _b = useReducer(providerReducer, __assign(__assign({}, providerState), { case: __assign(__assign({}, providerState.case), { uuid: caseUuid }) })), state = _b[0], dispatch = _b[1];
    var hasRun = useRef(false);
    useEffect(function () {
        if (!hasRun.current) {
            fetchCaseData()(dispatch, caseUuid);
            hasRun.current = true;
        }
    }, [caseUuid]);
    useEffect(function () {
        var _a;
        function markCaseAsRead() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.authCase.markCaseAsRead(caseUuid)];
                        case 1:
                            _a.sent();
                            fetchCaseData()(dispatch, caseUuid);
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (((_a = state === null || state === void 0 ? void 0 : state.case) === null || _a === void 0 ? void 0 : _a.state) === "NEW") {
            markCaseAsRead();
        }
    }, [caseUuid, (_a = state === null || state === void 0 ? void 0 : state.case) === null || _a === void 0 ? void 0 : _a.state]);
    var getCaseComponent = function () {
        if (state.initialLoading) {
            return _jsx(DSRView, {});
        }
        if (!state.initialLoading && (!state.case || !state.case.type)) {
            return _jsx(NotFound, {});
        }
        if (state.case.type === "AUTHORITY_INBOX") {
            return (_jsx(AuthorityView, { caseData: state.case, handleRefresh: function () { return fetchCaseData()(dispatch, caseUuid); } }));
        }
        if (state.case.type.includes("FADP") || state.case.type.includes("CCPA")) {
            return _jsx(DSRView, {});
        }
        return _jsx(NotFound, {});
    };
    return (_jsxs(StateContext.Provider, { value: state, children: [_jsx(DispatchContext.Provider, { value: dispatch, children: getCaseComponent() }), state.loading && _jsx("div", { className: "absolute top-0 left-0 w-full h-full min-h-screen z-10" })] }));
}
