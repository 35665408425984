import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import { useCase } from "pages/Client/Case/CaseContext";
import AddProcessorField from "pages/Client/Case/DSR/ClientView/Components/AddProcessorField";
import tolgee from "services/translation";
import { fetchCaseData, finalizeCheck, reopenCheck } from "store/thunks";
import InternalCommentModal from "pages/Client/Case/DSR/ClientView/Components/InternalCommentModal";
import { generateI18nKey, isCheckFinished } from "helpers/case";
import { BaseButton } from "components";
export default function InformThirdParty() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var requestClosed = caseData.state === "CLOSED";
    var isFinished = isCheckFinished(state);
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var handleReload = useCallback(function () { return fetchCaseData()(dispatch, caseData.uuid, false); }, [dispatch, caseData]);
    var handleNoDecision = function () {
        finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_NO");
    };
    return (_jsxs(_Fragment, { children: [_jsx("span", { children: tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "description"),
                }) }), _jsxs("div", { className: "flex flex-col space-y-2", children: [_jsxs("div", { children: [_jsx("span", { className: "mb-2", children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "description_add_processor_third_party"),
                                }) }), _jsx(AddProcessorField, { caseActors: caseData.case_actors, handleReload: handleReload, locked: !!isFinished, contactType: "THIRD_PARTY" })] }), _jsxs("div", { className: "flex space-x-2 self-end", children: [isFinished && !requestClosed && (_jsx(Button, { onClick: function () { return reopenCheck()(dispatch, caseData.uuid, state.ui.check); }, variant: "outlined", endIcon: _jsx(FontAwesomeIcon, { icon: "lock-open", className: "!text-sm" }), sx: {
                                    ml: 2,
                                }, children: tolgee.t({
                                    key: "general.reopen",
                                }) })), _jsx(BaseButton, { disabled: isFinished, color: "error", onClick: function () { return setOpen(true); }, children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "inform_third_parties.no"),
                                }) }), _jsx(BaseButton, { testid: "informThirdParty_confirm", disabled: isFinished, color: "success", onClick: function () {
                                    return finalizeCheck()(dispatch, caseData.uuid, state.ui.check, "INFORM_THIRD_PARTY_YES");
                                }, children: tolgee.t({
                                    key: generateI18nKey(caseData.type, state.ui.check, "inform_third_parties.yes"),
                                }) })] })] }), _jsx(InternalCommentModal, { modalState: [open, setOpen], handleReload: handleNoDecision, title: tolgee.t({
                    key: generateI18nKey(caseData.type, state.ui.check, "inform_third_parties.do_not_inform.modal_title"),
                }) })] }));
}
