var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, MenuItem, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { T } from "@tolgee/react";
import { useForm } from "react-hook-form";
import { BaseTextField } from "components";
import { api } from "services";
import tolgee from "services/translation";
import { notify } from "store/app";
import { allLang } from "const";
var products = ["art27", "ukrep", "swissrep", "turkeyrep"];
var allLangRecords = allLang.map(function (i) { return ({
    language: i,
}); });
export default function Snippet(_a) {
    var _this = this;
    var type = _a.type;
    var businessId = useParams().businessId;
    var dispatch = useDispatch();
    var _b = useState([]), data = _b[0], setData = _b[1];
    var _c = useState(), url = _c[0], setUrl = _c[1];
    var _d = useForm(), setValue = _d.setValue, watch = _d.watch;
    var selectedLanguage = watch("language");
    var language = selectedLanguage || "en";
    var fetchData = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.business.get(businessId)];
                case 1:
                    res = _a.sent();
                    if (!res) {
                        dispatch(notify({
                            message: tolgee.t({
                                key: "generic.smth_went_wrong",
                            }),
                            type: "ERROR",
                        }));
                        return [2 /*return*/];
                    }
                    setData(res === null || res === void 0 ? void 0 : res.features.filter(function (i) { return products.includes(i); }));
                    setUrl(res === null || res === void 0 ? void 0 : res.portal_url);
                    return [2 /*return*/];
            }
        });
    }); }, [businessId, dispatch]);
    useEffect(function () {
        tolgee.loadRecords(allLangRecords);
    }, []);
    useEffect(function () {
        fetchData();
    }, [businessId, fetchData]);
    var html = "<h2>".concat(tolgee.t({
        key: "all_lang.rep_policy_snippet_title",
        language: language,
    }), "</h2>\n\n").concat(tolgee.t({
        key: "all_lang.rep_policy_snippet_intro",
        language: language,
    }), "\n<ul>\n  ").concat(data === null || data === void 0 ? void 0 : data.map(function (i) { return "<li>\n      ".concat(tolgee.t({
        key: "all_lang.".concat(i),
        language: language,
    }), "\n  </li>"); }).join(""), "\n</ul>\n\n").concat(tolgee.t({
        key: "all_lang.rep_policy_snippet_cta",
        language: language,
    }), " <a href=").concat(url, ">").concat(url, "</a>");
    var copyHTML = function () {
        var _a;
        (_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText(html);
    };
    return (_jsx("div", { className: "box-outerlayout pt-10 mt-6", id: "policysnippets", "data-testid": "".concat(type, "-snippet"), children: _jsxs("div", { className: "md:p-6 w-full", children: [_jsx("h2", { children: _jsx(T, { keyName: "rep.snippets_to_include" }) }), _jsx("div", { children: _jsx(T, { keyName: "rep.".concat(type, "_snippet") }) }), _jsxs("div", { className: "font-bold mt-8", children: [_jsx(T, { keyName: "nis.select_lang" }), ":"] }), _jsx(TextField, { select: true, id: "select", className: "w-full md:w-100 mt-4", defaultValue: "en", value: selectedLanguage, onChange: function (event) {
                        var _a;
                        setValue("language", (_a = event.target) === null || _a === void 0 ? void 0 : _a.value);
                    }, children: allLang.map(function (i) { return (_jsx(MenuItem, { value: i, children: i.toUpperCase() }, i)); }) }), _jsxs("div", { className: "font-bold mt-8", children: [_jsx(T, { keyName: "nis.text_snippet" }), ":"] }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full", children: [_jsx("h2", { children: tolgee.t({
                                key: "all_lang.rep_policy_snippet_title",
                                language: language,
                            }) }), _jsx("div", { children: tolgee.t({
                                key: "all_lang.rep_policy_snippet_intro",
                                language: language,
                            }) }), _jsx("ul", { children: data === null || data === void 0 ? void 0 : data.map(function (i) { return (_jsx("li", { className: "list-disc", children: tolgee.t({
                                    key: "all_lang.".concat(i),
                                    language: language,
                                }) }, i)); }) }), _jsxs("div", { children: [tolgee.t({
                                    key: "all_lang.rep_policy_snippet_cta",
                                    language: language,
                                }), _jsx("a", { className: "ml-2", href: url, rel: "noreferrer", children: url })] })] }), _jsxs("div", { className: "font-bold mt-8", children: [_jsx(T, { keyName: "nis.html_snippet" }), ":"] }), _jsxs("div", { className: "border rounded-lg border-gray-300 bg-gray-50 p-4 my-4 !w-full", children: [_jsx("div", { className: "mb-6", children: _jsx(BaseTextField, { containerClassName: "w-full", multiline: true, disabled: true, value: html }) }), _jsx("div", { className: "flex justify-end mt-4", children: _jsxs(Button, { onClick: function () { return copyHTML(); }, children: [_jsx(FontAwesomeIcon, { className: "mr-2", icon: "copy", color: "gray", size: "lg" }), _jsx(T, { keyName: "nis.copy" })] }) })] })] }) }));
}
