import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VerticalDataValue } from "components";
import { generateConsumerInfo, generateContactData, generateI18nKey, getSender, } from "helpers/case";
import { useCase } from "pages/Client/Case/CaseContext";
import ConfirmInquiry from "pages/Client/Case/DSR/ClientView/Components/DSRFlow/CheckSteps/ConfirmInquiry";
import tolgee from "services/translation";
export default function DSIdentification() {
    var _a = useCase(), state = _a[0], dispatch = _a[1];
    var caseData = state.case;
    var checkStep = state.ui.check;
    var sender = getSender(caseData.case_actors);
    var senderInfo = generateContactData(sender);
    var consumerInfo = generateConsumerInfo(caseData);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "grid grid-cols-2 gap-4", children: _jsxs("div", { className: "col-span-2 ", children: [_jsx(ConfirmInquiry, { handleSendInquiry: function () {
                                return dispatch({
                                    type: "SEND_INQUIRY",
                                    payload: ["identification_1"],
                                });
                            }, declinePreview: caseData.type === "DSR_CCPA_LIMIT" || caseData.type === "DSR_CCPA_OPTOUT"
                                ? "FRAUDULENT"
                                : "IDENTIFICATION" }), _jsx("div", { className: "mt-4", children: tolgee.t({
                                key: generateI18nKey(caseData.type, checkStep, "description"),
                            }) })] }) }), caseData.extra.acting_for !== "MYSELF" && (_jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({
                            key: generateI18nKey(caseData.type, checkStep, "requester.".concat(caseData.extra.acting_for.toLowerCase())),
                        }) }), _jsx("div", { className: "mb-4", children: _jsxs("div", { className: "grid @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3", children: [senderInfo.map(function (i) { return (_jsx(VerticalDataValue, { title: tolgee.t({
                                        key: "generic.".concat(i.key),
                                    }), value: i.value }, i.key)); }), _jsx(VerticalDataValue, { title: tolgee.t({
                                        key: generateI18nKey(caseData.type, checkStep, "acting_for"),
                                    }), value: tolgee.t({
                                        key: generateI18nKey(caseData.type, checkStep, "acting_for.".concat(caseData.extra.acting_for.toLowerCase())),
                                    }) })] }) })] })), _jsxs(_Fragment, { children: [_jsx("h3", { className: "my-0 mb-1", children: tolgee.t({
                            key: generateI18nKey(caseData.type, checkStep, "data_subject"),
                        }) }), _jsx("div", { className: "mb-4", children: _jsx("div", { className: "grid  @lg:grid-cols-2 gap-x-2 gap-y-2 border border-slate-200 rounded-lg -mx-2 px-3 py-3 ", children: consumerInfo.map(function (i) { return (_jsx(VerticalDataValue, { title: tolgee.t({
                                    key: "generic.".concat(i.key),
                                }), value: i.value }, i.key)); }) }) })] })] }));
}
